const vehicleData = [
  {
    "Placa": "SZT056",
    "VIM": "LZZ7CLXC3PC466067",
    "CampanaActiva": 0
  },
  {
    "Placa": "ABC123",
    "VIM": "LZZ7CLXC3PC466063",
    "CampanaActiva": 0
  }
];

export default vehicleData;
