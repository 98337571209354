import { Helmet } from "react-helmet"
import BannerContacto from "../components/BannerContacto"
import Footer from "../components/Footer"
import NavBar from "../NavBar"
import NavBarTop from "../NavBarTop"
import env from '../config';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import data from '../data/vehicleData.js'; 
import { CenterContact } from "../components/CenterContact.jsx"
import FooterSinWp from "../components/FooterSinWp.jsx"
import ContactoTecnico from "../components/ContactoTecnico.jsx"

let urlMedia = env.url



export const Seguridad = () => {
    const [placaInput, setPlacaInput] = useState('');
    const [vimInput, setVimInput] = useState('');

    const handlePlacaInputChange = (event) => {
        setPlacaInput(event.target.value);
    };

    const handleVimInputChange = (event) => {
        setVimInput(event.target.value);
    };

    const buscarCampaña = (consulta) => {
        const resultado = data.find(
            (item) => (item.Placa === consulta || item.VIM === consulta)
        );

        // if (resultado) {
        //     if (resultado.CampanaActiva === 1) {
        //         toast.success('¡Tiene una campaña activa!', { autoClose: 3000 });
        //     } else {
        //         toast.warning('No tiene campañas activas.', { autoClose: 3000 });
        //     }
        // } else {
        //     toast.error('No se encontró la placa o VIM.', { autoClose: 3000 });
        // }

        if (resultado) {
            if (resultado.CampanaActiva === 1) {
                toast.success('¡Tiene una campaña activa!', { autoClose: 3000 });
            } else {
                toast.warning('No tiene campañas activas.', { autoClose: 3000 });
            }
        } else {
            toast.warning('No tiene campañas activas.', { autoClose: 3000 });
        }
    };

    const handlePlacaSearch = () => {
        const placaRegex = /^[A-Za-z]{3}\d{3}$/;
        if (placaRegex.test(placaInput.trim())) {
            buscarCampaña(placaInput);
        } else {
            toast.error('Formato de placa incorrecto. Debe ser: ABC123', { autoClose: 3000 });
        }
    };

    const handleVimSearch = () => {
        const vimRegex = /^[A-Za-z0-9]{17}$/;
        if (vimRegex.test(vimInput.trim())) {
            buscarCampaña(vimInput);
        } else {
            toast.error('Formato de VIM incorrecto. Debe tener 17 caracteres alfanuméricos.', { autoClose: 3000 });
        }
    };
    return (
        <>
            <Helmet>
                <title>Campañas de Seguridad | Vehicentro</title>
                <meta name="description" content="Experiencia sinotruk" />
            </Helmet>
            <NavBarTop />
            <div className="ContenedorContac">
                <img className="contactoFoto" src={urlMedia + "/seguridad/campanasSeguridad.jpg"} alt="" title="" />
            </div>
            <div className="ContenedorContacCelu">
                <img className="contactoFoto" src={urlMedia + "/seguridad/campanasSeguridadCelu.jpg"} alt="" title="" />
            </div>
            <div className="contenedorSeguridad">
                <div className="contenedorTextoSeguridad">
                    <h1>CAMPAÑAS DE SEGURIDAD</h1>
                    <br />
                    <div className="contenedorTextoSeguridadInterno">
                        <p>
                            Para Vehicentro Colombia es muy importante la seguridad de nuestros clientes, por esta razón se
                            generan acciones de forma preventiva o correctiva de los vehículos comercializados de la marca
                            Sinotruk con el objetivo de mejorar aspectos relacionados con: seguridad, comportamiento, satisfacción,
                            actualización de software o cualquier otro requerimiento especial, para prevenir daños en el vehículo,
                            mejorar otro desempeño o alargar su vida util.
                        </p>
                        <br />
                        <p>
                            Valida si tu vehículo tiene alguna campaña de seguridad pendiente por aplicar.
                            recuerda que las campañas de fábrica, <span>no generan ningún costo</span>
                        </p>
                        <br />
                        <p>
                            Consulta <span>aquí</span> si tú vehículo tiene alguna campaña de seguridad vigente:
                        </p>
                    </div>
                </div>
                <div className="contenedorBotonones">
                    <div className="busquedaPlaca">
                        <input
                            id="1"
                            className="inputBusqueda"
                            placeholder="ESCRIBE TU PLACA"
                            value={placaInput}
                            onChange={handlePlacaInputChange}
                        />
                        <button id="1" className="buscarButton" onClick={handlePlacaSearch}>
                            Buscar
                        </button>
                    </div>
                    <div className="busquedaVim">
                        <input
                            id="2"
                            className="inputBusqueda"
                            placeholder="BUSCAR POR VIM"
                            value={vimInput}
                            onChange={handleVimInputChange}
                        />
                        <button id="2" className="buscarButton" onClick={handleVimSearch}>
                            Buscar
                        </button>
                    </div>
                </div>
                <br/>
                <br/>
                <h2>Para agendar tu cita por favor comunícate con nosotros a través de los siguientes medios</h2>
                <br/>
            </div>
            <CenterContact/>
            <ToastContainer />
            <ContactoTecnico/>
           <FooterSinWp/>
        </>
    )
}
