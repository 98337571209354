import { Helmet } from "react-helmet"
import BannerContacto from "../components/BannerContacto"
import Footer from "../components/Footer"
import NavBar from "../NavBar"
import NavBarTop from "../NavBarTop"
import env from '../config';
import React, { useState } from 'react';
import { CenterContact } from "../components/CenterContact"


let urlMedia = env.url

function Contactanos() {
    return (
        <>
            <Helmet>
                <title>Contáctanos | Vehicentro</title>
                <meta name="description" content="Experiencia sinotruk" />
            </Helmet>
            <NavBarTop />
            <div className="ContenedorContac">
                <img className="contactoFoto" src={urlMedia + "/contacto/Contactanos.jpg"} alt="" title="" />
            </div>
            <div className="ContenedorContacCelu">
                <img className="contactoFoto" src={urlMedia + "/contacto/ContactanosCelular.jpg"} alt="" title="" />
            </div>
            <div className="horarioAten">
                <p><span>Horario de atención:</span> Lunes a Viernes 8:30 a.m. a 5:30 p.m.</p>
                <p>Sábados: 8:00 a.m. a 12:30 p.m.</p>
            </div>
            <CenterContact/>
            <BannerContacto />
            <Footer />
        </>
    )
}



export default Contactanos