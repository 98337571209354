import { useEffect } from 'react';
import NavBarTop from '../NavBarTop';
import BannerContacto from '../components/BannerContacto';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import env from '../config';
let urlMedia = env.url

function Concesionarios() {

  const datosConcesionarios = [{
    id: "Bogota",
    datos: [{
      nombre: "BOGOTÁ, SEDE FONTIBÓN",
      descripcion: "Punto de Venta, Talleres y Repuestos",
      telefono: "032-994740 | 0992048229",
      direccion: "Sector Fontibón CR 126A #17-50",
      url: "https://goo.gl/maps/iUnCP1YSMsv9ibpQA",
      img: urlMedia + "concesionarios/fontibon.jpg",
      alt: ""
    }, {
      nombre: "BOGOTÁ, SEDE CALLE 80",
      descripcion: "Punto de Venta y Repuestos",
      telefono: "032-994740 | 0992048229",
      direccion: "Sector Cota sentido Siberia - Bogotá  Kilometro 2.2",
      url: "https://www.google.com/maps/place/Sinotruk+-+Sede+Calle+80/@4.7450731,-74.1417551,17z/data=!3m1!4b1!4m6!3m5!1s0x8e3f9b7383578045:0x11b5288aa3e231a3!8m2!3d4.7450731!4d-74.1417551!16s%2Fg%2F11v3_62jsc?entry=ttu",
      img: urlMedia + "concesionarios/calle80.jpg",
      alt: ""
    }]
  }]


  useEffect(() => {
    const navButtons = document.querySelectorAll(".btnConcesionarios");
    navButtons.forEach((link, index) => {
      link.addEventListener("click", (ev) => {
        clickConcesionario(ev.target.getAttribute('option'))
      })
    })

    const clickConcesionario = (val) => {
      let container = document.querySelector(".boxConcesionarios")

      let buscarDatos = datosConcesionarios.find((dato) => dato.id == val)
      let datosGrid = buscarDatos.datos
      let auxTable = ""

      // datosGrid.forEach((datos) => {
      //   auxTable += "<div class='concesionariosFlex'><div class='concesionariosFlexText textConcesionario'><p class='nombreConcesionario'>" + datos.nombre + "</p><p class='descConcesionario'>" + datos.descripcion + "</p><span class='direccionConcesionario'>" + datos.direccion + "</span><p class='telefonoConcesionario'><span>Línea directa </span>" + datos.telefono + "</p><span class='linkConcesionario'><a href='" + datos.url + "' target='_blank'>UBICACIÓN</a></span></div><div class='concesionariosFlexImg imgConcesionario'><img src='" + datos.img + "' alt='" + datos.alt + "' /></div></div>"
      // })
      datosGrid.forEach((datos) => {
        auxTable += "<div class='concesionariosFlex'><div class='concesionariosFlexText textConcesionario'><p class='nombreConcesionario'>" + datos.nombre + "</p><p class='descConcesionario'>" + datos.descripcion + "</p><span class='direccionConcesionario'>" + datos.direccion + "</span><p class='telefonoConcesionario'></p><span class='linkConcesionario'><a href='" + datos.url + "' target='_blank'>UBICACIÓN</a></span></div><div class='concesionariosFlexImg imgConcesionario'><img src='" + datos.img + "' alt='" + datos.alt + "' /></div></div>"
      })

      container.innerHTML = auxTable
    }

  })
  return (
    <>
      <Helmet>
        <title>Concesionarios en Colombia | Vehicentro</title>
        <meta name="description" content="Visitanos en nuestros concesionarios en Bogota: Bogotá sede Fontibón y Bogota sede calle 80" />
        <meta name="keywords" content="camion, camiones, venta de camiones, camiones en colombia, tracto"></meta>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <NavBarTop />
      <p className="tituloConcesionario">ENCUENTRA TU CONCESIONARIO MÁS CERCANO</p>
      <div className="btnConcesionarios">
        <a className='btn6' option="Bogota" href='#'>BOGOTÁ</a>
      </div>
      <div className="boxConcesionarios">
        <span className='spanText'>Selecciona el concesionario en la ciudad más cercana</span>
      </div>
      <BannerContacto />
      <Footer />
    </>
  )

}

export default Concesionarios