import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../modalContact.css';
import FormContactFicha from "./FormContactFicha";

export default function ContactoTecnico(props){
  let url = props.url
  let nomCamion = props.camion
  let listSerie = (props.serie=="")?'':props.serie
  let listCamion = (props.camionSerie=="")?'':props.camionSerie
 
  const [modalContactIsOpen, setContactIsOpen] = useState(false);
  const [modalFormIsOpen, setFormIsOpen] = useState(false);

    useEffect(() => {
      const keyDownHandler = event => {
        if (event.key === 'Escape') {
          event.preventDefault();
          closeContact()
          closeFormContact()
        }
      };
  
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, []);

    function openModal() {
      openFormContact();
      closeContact()
    }

    function openFormContact(){
      setFormIsOpen(true)
    }
  
    function closeFormContact(){
      setFormIsOpen(false)
    }

    function openContact(){
      url = props.url
      setContactIsOpen(true)
    }

    function openContactDos(){
      url = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOBOTONCOT"
      setContactIsOpen(true)
    }

    function closeContact(){
      setContactIsOpen(false)
    }

    const closeContactF = ()=>{
      setContactIsOpen(false)
    }


    function gotoWs(){
      window.open('https://api.whatsapp.com/send/?phone=573203977352', '_blank')
      closeContact()
    }

    function gotoCall(){
      window.open("tel:3138720398")
      closeContact()
    }

  return (
    <>
          <div className="boxesContact posRelative fix">
              <p className="buttonContact f fixed" onClick={openContactDos}><a className="tecnico" href="https://wa.me/573147082286?text=Hola,%20Vehicentro%20Colombia">Habla con el técnico.</a></p>
          </div>

    </>

)
}