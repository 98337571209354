import NavBar from "../NavBar"
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer'
import BannerContacto from '../components/BannerContacto'
import { useEffect, useState } from "react";
import { Slide } from 'react-slideshow-image';
import env from '../config';
import { Helmet } from "react-helmet";
import FormContactFicha from "../components/FormContactFicha";
import videoFabrica from '../../src/media/videos/videoFabrica.mp4'



let bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOBOTONCOT"
let nombreCamion = ""
let nombreSerie = "Serie 100"
let camionSerie = ""
let audio_motor = "../images/10-toneladas/motor.mp3"
let urlMedia = env.url
let prueba




function Inicio2() {

    
    

    const [modalFormIsOpen, setFormIsOpen] = useState(false);


    // useEffect(() => {
    //     const motorPlayer = document.getElementById('audioMotorHome')
        
    //     motorPlayer.src = audio_motor

    //     const keyDownHandler = event => {
    //         if (event.key === 'Escape') {
    //             event.preventDefault();
    //             closeFormContact()
    //         }
    //     };

    //     const videoContainer = document.getElementById('videoContainer')
    //     const imgContainer = document.querySelectorAll('.imgContainerHeader')
    //     const mouseOverHandler = event => {
    //         if (!videoContainer.classList.contains("video50"))
    //             videoContainer.classList.add("video50");

    //         imgContainer.forEach(a => {
    //             if (!a.classList.contains("img25")) {
    //                 a.classList.add("img25")
    //                 a.querySelector('.linksBox').classList.add("imgHidden")
    //             }
    //         })
    //     }

    //     const mouseLeaveHandler = event => {
    //         if (videoContainer.classList.contains("video50")) {
    //             videoContainer.classList.remove("video50");
    //         }
    //         imgContainer.forEach(a => {
    //             if (a.classList.contains("img25")) {
    //                 a.classList.remove("img25")
    //                 a.querySelector('.linksBox').classList.remove("imgHidden")
    //             }
    //         })
    //     }

    //     videoContainer.addEventListener('mouseenter', mouseOverHandler);
    //     videoContainer.addEventListener('mouseleave', mouseLeaveHandler);


    //     document.addEventListener('keydown', keyDownHandler);
    //     return () => {
    //         document.removeEventListener('keydown', keyDownHandler);
    //     };

    // }, []);


    function openFormContact() {
        setFormIsOpen(true)
    }

    function closeFormContact() {
        setFormIsOpen(false)
    }



    return (
        <>
            <Helmet>
                <title>Vehicentro | Importadores de la marca SINOTRUK. Venta de camiones</title>
                <meta name="description" content="Camiones de venta en Colombia · Puntos vehicentro a nivel nacional · VISÍTANOS EN BOGOTA" />
                <meta name="keywords" content="camion, camiones, venta de camiones, camiones en colombia, bogota, camiones, tracto, super minimula, minimula, super turbo, frs, nqs , frs super turbo"></meta>
                <meta name="robots" content="index, follow"></meta>
            </Helmet>
            <NavBar />   
            <Block1 />
            <Menu2 />
            <Menu3 />
            <Block2 />
            <Block3 />
            <Block4 />
            <Banner />
            <BannerContacto url={bdc} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} />
            <Footer />
            <Modal />
        </>
    )
}

function Block1() {
    return (
        <div className="block1 boxBlock" height="1080">
            <div id="videoContainer">
                <div className="boxRight tecnologia" height="1080">
                    <div className="boxLeft c7h">
                        <video preload="none" autoPlay={true} width="930" height="1080" playsInline={true} controls loop muted id="myVideo" src={urlMedia + "home/videoIncio.mp4"} className="videoWidth produ" />
                    </div>
                </div>
             
                <div className="overlayBox" height="200">
                    <p className="titleBox">PRODUCTOS</p>
                    <div className="linksBox new">
                        <NavLink to="">SERIE <span className="boldserie">100</span></NavLink>
                        {/* <NavLink src="" to="/serie/100">SERIE <span className="boldserie">100</span></NavLink> */}
                        {/* <NavLink src="" to="/serie/tx-camiones-sencillos">SERIE <span className="boldserie">TX CAMIONES SENCILLOS</span></NavLink> */}
                        <NavLink to="">SERIE <span className="boldserie">TX</span></NavLink>
                        {/* <NavLink src="" to="/serie/tx-camiones-4x2-6x4">SERIE <span className="boldserie">TX TRACTOCAMIONES 4X2 Y CHASIS CAMION 6X4</span></NavLink> */}
                        <NavLink to="">SERIE <span className="boldserie">G7</span></NavLink>
                        {/* <NavLink src="" to="/serie/g7-tractocamiones-6x4">SERIE <span className="boldserie">G7 TRACTOCAMIONES 6X4</span></NavLink> */}
                    </div>
                </div>
            </div>
            <div className="imgContainerHeader imgHeader1" height="600">

                
            </div>
            <div id="videoContainer" className="responsive">
                <div className="boxRight tecnologia" height="1080">
                    <div className="boxLeft c7h">
                        <video preload="none" autoPlay={true} width="930" height="1080" playsInline={true} controls loop muted id="myVideo" src={urlMedia + "home/testimonio-movil.mp4"} className="videoWidth produ" />
                    </div>
                </div>
                <div className="overlayBox" height="200">
                    <NavLink to="/experiencia" className="titleBox">SINOTRUKEROS</NavLink>
                    <div className="linksBox testimonio">
                        <NavLink to="/experiencia" className="line2" src="">VIDEOS TESTIMONIALES</NavLink>
                    </div>
                </div>
                <h1>Venta de camiones en Colombia</h1>
                <h2>En vehicentro encuentra camiones Sinotruk</h2>
            </div>
            <div className="imgContainerHeader imgHeader2 noResponsive" height="600">
                <div className="overlayBox" height="200">
                    <NavLink to="/experiencia" className="titleBox">SINOTRUKEROS</NavLink>
                    <div className="linksBox testimonio">
                        <NavLink to="/experiencia" className="line2" src="">VIDEOS TESTIMONIALES</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Block2() {
    return (
        < div className="block2 noResponsive" >
            <video preload="none" width="1920" height="1076" controls autoPlay={true} playsInline={true} muted loop src={urlMedia + "home/colombiaInicioVein.mp4"} className="videoWidth" />
        </div >
    )
}


function Block3() {

    const [modalFormIsOpen, setFormIsOpen] = useState(false);

    function openFormContact(cam) {
        console.log(cam)

        switch (cam) {

            //NQS
            case "NQS-9.9-Ton":
                nombreCamion = "NQS 9.9 Ton"
                camionSerie = "NQS 9.9 Ton"
                nombreSerie = "Serie 100"
                bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCO"

                break;
            //Volquetas
            case "Volquetas":
                nombreCamion = "Volquetas"
                camionSerie = "Volquetas"
                nombreSerie = "Volquetas"
                bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOVOL"

                break;
            //Mixer
            case "Mixer":
                nombreCamion = "Mixer"
                camionSerie = "Mixer"
                nombreSerie = "Mixer"
                bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOMIX"

                break;

            default:
            break;
        }


        setFormIsOpen(true)
    }

    function closeFormContact() {
        setFormIsOpen(false)
    }

    return (
        <>
            <div className="block3 boxBlock">
                <div>
                    {/* <NavLink to="/familia/100"><img src={urlMedia + "home/serie-100.webp"} width="500" height="389" alt="Camiones de carga liviana en Ecuador" /></NavLink> */}
                    <NavLink ><img src={urlMedia + "home/familia100.webp"} width="500" height="389" alt="serie 100 Vehicentro Colombia Sinotruk Colombia" /></NavLink>
                    <div className="bottomContainer">
                        <p className="titleBox">SERIE</p>
                        {/* <NavLink className="titleBox2" to="/familia/100">100</NavLink> */}
                        <NavLink className="titleBox2">100</NavLink>
                        <div className="linksBox">
                            {/* <a className="line2" to="/camiones/NQS" onClick={() => { openFormContact("camion-NQS-156HP-9900") }}>- Chasís NQS 9.9 Ton</a><br/>
                            <a className="line2" to="/camiones/FRS" onClick={() => { openFormContact("camion-FRS-170HP-10500") }}>- Chasís FRS 10.4 Ton</a> */}
                            <NavLink className="line2" to="/camiones/NQS-9.9-Ton" >- NQS 9.9 Ton</NavLink>
                            <NavLink className="line2" to="/camiones/FRS-10.4-Ton-Super-Turbo" >- FRS 10.4 Ton Súper Turbo</NavLink>
                        </div>
                    </div>
                </div>
                <div>


                    <Slide duration={2000}>

                    <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/testimonios/arnulfo.jpg)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/testimonios/carmen.jpg)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/testimonios/jussy.jpg)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/testimonios/luis.jpg)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/testimonios/oscar.jpg)`, height: `23.2vw` }}>
              </div>
            </div>
                    </Slide>


                    <div className="bottomContainer">
                        <div className="bannerTec">
                            <br/>
                            <br/>
                            <img src={urlMedia + "home/logoale.png"} alt="Tecnologia Alemana Vehicentro Colombia Sinotruk Colombia" />
                            <img src={urlMedia + "home/logoeuro.png"} alt="Euro 6 Vehicentro Colombia Sinotruk Colombia" />
                        </div>
                        {/* <div className="boxPlayerMotor audiohome">
                            <audio id="audioMotorHome" controls type="audio/mpeg"></audio>
                        </div> */}
                        <div className="linksBox iniciocentrado">
                            <NavLink className="line2 testimoniosChina" to="/testimonios
                            ">TESTIMONIOS DELEGACIÓN COLOMBIANA EN CHINA</NavLink>
                        </div>
                    </div>
                </div>
                <div>


          <Slide duration={2000}>

            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/home/familiaSencillo.webp)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/co/images/home/familiatracto4x26x4bIEN.webp)`, height: `23.2vw` }}>
              </div>
            </div>
          </Slide>


          <div className="bottomContainer">
            <p className="titleBox">SERIE</p>
            <NavLink className="titleBox2" to="">TX</NavLink>
            <div className="linksBox">
            <NavLink className="line2" to="/camiones/TX-250-4x2-PBV-17.000-Chasis">- TX 250 4x2 PBV 17.000 Chasís</NavLink><br></br>
            <NavLink className="line2" to="/cabezales/TX-350-4x2-Minimula">- TX 350 4x2 Minimula</NavLink>
            <NavLink className="line2" to="/cabezales/TX-400-4x2-Super-Minimula">- TX 400 4x2 Súper Minimula</NavLink>
            {/* <h1>{(window.screen.width)}x{(window.screen.height)} </h1> */}
            </div>
          </div>

        </div>

        <div>
                    <NavLink > <img src={urlMedia + "home/familiag7.webp"} width="900" height="700" alt="serie g7 Vehicentro Colombia Sinotruk Colombia" /> </NavLink>
                    {/* <NavLink to="/familia/g7-tractocamiones-6x4"> <img src={urlMedia + "home/serie-c7h.webp"} width="900" height="700" alt="Cabezales de venta en Ecuador" /> </NavLink> */}
                    <div className="bottomContainer">
                        <p className="titleBox">SERIE</p>
                        {/* <NavLink className="titleBox2" to="/familia/g7-tractocamiones-6x4">G7 TRACTOCAMIONES 6X4</NavLink> */}
                        <NavLink className="titleBox2">G7</NavLink>
                        <div className="linksBox">
                            <NavLink className="line2" to="/cabezales/G7-540-Tracto-AMT">- G7 540 Tracto AMT</NavLink>
                            {/* <a className="line2" to="/cabezales/cabezal-52000-AMT" onClick={() => { openFormContact("TRACTO-MULA-C7H-540HP-52000-AMT") }}>- G7 540 Tracto Automatizada</a> */}
                        </div>
                    </div>
                </div>
                
                <div>


          <Slide duration={2000}>

            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/images/home/volquetaC7HNew.webp)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(../images/home/serie-volquetas.webp)`, height: `23.2vw` }}>
              </div>
            </div>
            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(https://www.vehicentro.com/images/home/volqueta7.webp)`, height: `23.2vw` }}>
              </div>
            </div>
          </Slide>


          <div className="bottomContainer">
            <p className="titleBox"></p>
            {/* <NavLink className="titleBox2" to="/proximamente">VOLQUETAS</NavLink> */}
            <a className="titleBox2" onClick={() => { openFormContact("Volquetas") }}>VOLQUETAS</a>
            <div className="linksBox">
              {/* <NavLink className="line2" src="" to="/proximamente">Próximamente</NavLink> */}
               <a className="line2" onClick={() => { openFormContact("Volquetas") }}>PRODUCTOS CONFIGURADOS A LA NECESIDAD DEL CLIENTE</a>
            </div>
          </div>

        </div>

        <div>


         

            <div className="each-slide-effect">
              <div style={{ 'backgroundImage': `url(../images/home/mixer.webp)`, height: `23.2vw` }}>
              </div>
            </div>
          


          <div className="bottomContainer">
            <p className="titleBox"></p>
            {/* <NavLink className="titleBox2" to="/proximamente">Mixer</NavLink> */}
            <a className="titleBox2" onClick={() => { openFormContact("Mixer") }}>MIXER</a>
            <div className="linksBox">
              {/* <NavLink className="line2" src="" to="/proximamente">Próximamente</NavLink> */}
              <a className="line2" onClick={() => { openFormContact("Mixer") }}>PRODUCTOS CONFIGURADOS A LA NECESIDAD DEL CLIENTE</a>
            </div>
          </div>

        </div>
                
            </div>

            {modalFormIsOpen &&
                <FormContactFicha url={bdc} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} close={closeFormContact} />
            }
        </>
    )
}

function Block4() {
    return (
        <div className="block4 boxBlock boxesIni posRelative content3">
            <div className="boxLeft playMotor colorLinksSobreTexto">
                <div className="boxLeft c7h">
                    <img src={urlMedia + "home/alianzaWeb.webp"} alt="Alianza Sinotruk man Vehicentro Colombia Sinotruk Colombia"/>
                </div>
            </div>
            <div className="boxRight tecnologia">
        <div className="boxLeft c7h">
          <video preload="none" controls autoPlay playsInline muted loop src={urlMedia + "home/potenciaColombia.mov"} className="videoWidth" />
        </div>
      </div>
        </div>
    )
}

function Menu2() {
    return (
        <div className="ocultarMenu">
            <nav className="menu2 home2">
            <ul className="nav-links-h">
                    <li><a className="sunward mouseA" option="sinotruk">CAMIONES</a></li>
                    <li><NavLink to="/concesionarios" className="concesionarios mouseA">CONCESIONARIOS</NavLink></li>
                </ul>
                <a href="tel:573106392803" className="telefonoInicio">+57 310 6392803</a>
            </nav>
        </div>
    )
}

 function Menu3() {
     return (
         <div className="barraNavegacion">
             <div className="barraLinks">
                 <a className="sinotruk mouseA" option="sinotruk">CAMIONES</a>
                 <NavLink to="/concesionarios">CONCESIONARIOS</NavLink>
             </div>
             <div className="numeroNavegacion">
                 <img src='https://vehicentro.com/co/static/media/icono-telefono.b5ccaa492bf4fcee7163.webp' alt="phone Vehicentro Colombia Sinotruk Colombia"></img>
             <a href="tel:573106392803">+57 310 6392803</a>
             </div>
         </div>
     )
 }



function Banner() {
    return (
        <div className="posRelative banner">
            <NavLink to="/concesionarios"><img className="desktopImg slideMain" src={urlMedia + "home/mapaCompuNew.jpg"} width="1790" height="590" alt="Puntos de Venta de Camiones, nqs, frs, chasis, minimula, super minimula, tracto, Sinotruk en Colombia Vehicentro Colombia Sinotruk Colombia  - Web" /></NavLink>
            <img className="movilImg slideMain" src={urlMedia + "home/mapaCellNew.jpg"} width="2432" height="1675" alt="Puntos de Venta de Camiones, nqs, frs, chasis, minimula, super minimula, tracto, Sinotruk en Colombia Vehicentro Colombia Sinotruk Colombia  - movil" />
            <div className="boxText flex-table row">
                <NavLink to="/concesionarios"><div className="flex-row textMain textMain2 noResponsive">BOGOTÁ, SEDE FONTIBÓN</div></NavLink>
            </div>
        </div>
    )
}

function Modal() {
    return (
        <div id="modalContainer"></div>
    )
}

export default Inicio2