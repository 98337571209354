import './styles.css';
import './responsive.css'
import { NavLink } from "react-router-dom"
import { useEffect } from "react";

import env from './config';

export default function NavBarTop() {
    var auxMovil = 0;

    let urlMedia = env.url

    useEffect(() => {
        window.scrollTo(0, 0);
        const navSlideClickH = () => {
            const burger = document.querySelector(".burger");
            const navLinks = document.querySelectorAll(".nav-links-h a");
            const navLinksIn = document.querySelectorAll(".rowMenu .colItem");

            navLinksIn.forEach((link, index) => {
                link.addEventListener("click", (ev) => {
                    burger.click()
                })
            })

            navLinks.forEach((link, index) => {
                link.addEventListener("click", (ev) => {

                    switch (ev.target.getAttribute('option')) {
                        case "concesionarios":
                            menuSelect("concesionarios")
                            break;
                        case "sinotruk":
                            menuSelect("sinotruk")
                            break;
                        // case "sunward":
                        //     menuSelect("sunward")
                        //     break;
                        case "compania":
                            menuSelect("compania")
                            break;
                    }
                })
            })
        }


        const menuSelect = (varTest) => {
            switch (varTest) {
                case "sinotruk":
                    document.querySelector(".item-level-0").classList.remove("nav-header-h")
                    document.querySelector(".item-level-0").classList.add("nav-header-s")
                    document.querySelector(".item-level-0").classList.add("nav-header-s-i")

                    // document.querySelector(".item-level-1").classList.remove("nav-header-s")
                    // document.querySelector(".item-level-1").classList.add("nav-header-h")

                    document.querySelector(".item-level-3").classList.remove("nav-header-s")
                    document.querySelector(".item-level-3").classList.add("nav-header-h")

                    document.querySelector(".item-level-6").classList.remove("nav-header-s")
                    document.querySelector(".item-level-6").classList.add("nav-header-h")
                    break;
                    //  case "sunward":
                    //      document.querySelector(".item-level-1").classList.remove("nav-header-h")
                    //      document.querySelector(".item-level-1").classList.add("nav-header-s")
                    //      document.querySelector(".item-level-1").classList.add("nav-header-s")    
                    
                    //      document.querySelector(".item-level-0").classList.remove("nav-header-s")
                    //      document.querySelector(".item-level-0").classList.add("nav-header")
                    //      document.querySelector(".item-level-3").classList.remove("nav-header-s")
                    //      document.querySelector(".item-level-3").classList.add("nav-header")
                    //      document.querySelector(".item-level-6").classList.remove("nav-header-s")
                    //      document.querySelector(".item-level-6").classList.add("nav-header")
                     
                    //      break;
                case "concesionarios":
                    document.querySelector(".item-level-3").classList.remove("nav-header-h")
                    document.querySelector(".item-level-3").classList.add("nav-header-s")
                    document.querySelector(".item-level-3").classList.add("nav-header-s-i")

                    document.querySelector(".item-level-0").classList.remove("nav-header-s")
                    document.querySelector(".item-level-0").classList.add("nav-header-h")

                    // document.querySelector(".item-level-1").classList.remove("nav-header-s")
                    // document.querySelector(".item-level-1").classList.add("nav-header-h")

                    document.querySelector(".item-level-6").classList.remove("nav-header-s")
                    document.querySelector(".item-level-6").classList.add("nav-header-h")

                    break;
                case "compania":
                    document.querySelector(".item-level-6").classList.remove("nav-header-h")
                    document.querySelector(".item-level-6").classList.add("nav-header-s")
                    document.querySelector(".item-level-6").classList.add("nav-header-s-i")

                    document.querySelector(".item-level-0").classList.remove("nav-header-s")
                    document.querySelector(".item-level-0").classList.add("nav-header-h")

                    // document.querySelector(".item-level-1").classList.remove("nav-header-s")
                    // document.querySelector(".item-level-1").classList.add("nav-header-h")

                    document.querySelector(".item-level-3").classList.remove("nav-header-s")
                    document.querySelector(".item-level-3").classList.add("nav-header-h")
                    break;
            }

        }

        const resetMenu = () => {
            document.querySelector(".item-level-0").classList.remove("nav-header-h")
            document.querySelector(".item-level-0").classList.add("nav-header-s")
            // document.querySelector(".item-level-1").classList.remove("nav-header-s")
            // document.querySelector(".item-level-1").classList.add("nav-header-h")
            document.querySelector(".item-level-6").classList.remove("nav-header-s")
            document.querySelector(".item-level-6").classList.add("nav-header-h")
        }

        const navSlide = () => {
            const burger = document.querySelector(".burger");
            const sinotruk = document.querySelector(".sinotruk");
            // const sunward = document.querySelector(".sunward");
            const nav = document.querySelector(".nav-links");
            const navh = document.querySelector(".nav-links-h");

            const navLinks = document.querySelectorAll(".nav-links a");
            const navSearch = document.querySelectorAll("div.navSearch");
            const burgerEvent = () => {
                nav.classList.toggle("nav-active");
                navSearch[0].classList.toggle("nav-search-active");

                document.querySelector('nav > .logo').classList.toggle("logoNav");
                navh.classList.toggle("nav-inactive");

                navLinks.forEach((link, index) => {
                    if (link.style.animation) {
                        link.style.animation = "";
                    } else {
                        link.style.animation = `navLinkFade 0.4s ease forwards 0.5s `;
                    }
                });

                document.querySelector(".internal").classList.add("logob")
                if (auxMovil == 0)
                    resetMenu();

                if (burger.classList.contains('toggle')) {

                } else {
                    document.querySelector(".internal").classList.remove("logob")
                }
            }
            burger.addEventListener("click", burgerEvent);
            sinotruk.addEventListener("click", burgerEvent);
            // sunward.addEventListener("click", burgerEvent);
            const closeBtnMovil = document.querySelectorAll(".closeMovil");
            for (var i = 0; i < closeBtnMovil.length; ++i) {
                let dataId = closeBtnMovil[i].getAttribute('data-id')
                closeBtnMovil[i].addEventListener("click", () => {
                    resetMenuMovil(dataId)
                    auxMovil = 1
                })
            }


        }

        const resetMenuMovil = (opt) => {
            switch (opt) {
                case "0":
                    document.querySelector(".item-level-0").classList.toggle("nav-header-h")
                    document.querySelector(".item-level-0").classList.toggle("nav-header-s")
                    break;
                // case "1":
                //     document.querySelector(".item-level-1").classList.toggle("nav-header-h")
                //     document.querySelector(".item-level-1").classList.toggle("nav-header-s")
                //     break;
                case "3":
                    document.querySelector(".item-level-3").classList.toggle("nav-header-h")
                    document.querySelector(".item-level-3").classList.toggle("nav-header-s")
                    break;
                case "6":
                    document.querySelector(".item-level-6").classList.toggle("nav-header-h")
                    document.querySelector(".item-level-6").classList.toggle("nav-header-s")
                    break;
                default:
                    break;
            }
        }

        const navSlideClick = () => {
            const navLinks = document.querySelectorAll(".nav-links a");

            navLinks.forEach((link, index) => {
                link.addEventListener("click", (ev) => {

                    switch (ev.target.getAttribute('option')) {
                        case "concesionarios":
                            menuSelect("concesionarios")
                            break;
                        case "sinotruk":
                            menuSelect("sinotruk")
                            break;
                    //    case "sunward":
                    //        menuSelect("sunward")
                    //         break;
                        case "compania":
                            menuSelect("compania")
                            break;
                    }
                })
            })
        };
        navSlide()
        navSlideClick()
        navSlideClickH()
    }, []);


    return <nav className="internal">
        <div className="burger">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
        </div>
        <div className="logo">
            <NavLink to="/"><img src={urlMedia+"/home/vehicentroNewColombia2.png"} width="300" /></NavLink>
        </div>
        <ul className="nav-links">
            <li><NavLink className="" to="/">INICIO</NavLink></li>
            <li>
                <a className="" href="#" option="sinotruk">CAMIONES</a>
                <div className="item-level-0 nav-header nav-header-h" >
                    <div className="headerSubMenu">
                        <div className="closeMovil" data-id="0"></div>
                        <p className="tituloMenu">CAMIONES</p>
                        <img className="imgnMenu" src={urlMedia+"navBarSinotruk.webp"} alt="Sinotruck" />
                    </div>
                    <div className="rowMenu">
                        <div className="colMenu">
                        <p className="colTitle">serie 100</p>
                            <p className="colItem meniu"><NavLink to="/camiones/NQS-9.9-Ton" >- NQS 9.9 Ton</NavLink></p>
                            <p className="colItem meniu"><NavLink to="/camiones/FRS-10.4-Ton-Super-Turbo" >- FRS 10.4 Ton Súper Turbo</NavLink></p>
                        </div>
                        <div className="colMenu">
                        <p className="colTitle">serie tx</p>
                            <p className="colItem meniu"><NavLink to="/camiones/TX-250-4x2-PBV-17.000-Chasis">- TX 250 4x2 PBV 17.000 Chasís</NavLink></p>
                            <p className="colItem meniu"><NavLink to="/cabezales/TX-350-4x2-Minimula">- TX 350 4x2 Minimula</NavLink></p>
                            <p className="colItem meniu"><NavLink to="/cabezales/TX-400-4x2-Super-Minimula">- TX 400 4x2 Súper Minimula</NavLink></p>

                        </div>
                        <div className="colMenu">
                        <p className="colTitle">Serie g7</p>
                            <p className="colItem meniu"><NavLink to="/cabezales/G7-540-Tracto-AMT">- G7 540 Tracto AMT</NavLink></p>

                        </div>
                    </div>
                </div>
            </li>
            <li>
                <NavLink to="/concesionarios">CONCESIONARIOS</NavLink>
                <div className="item-level-3 nav-header nav-header-h" >
                    <div className="headerSubMenu">
                        <div className="closeMovil" data-id="3"></div>
                        <p className="tituloMenu">CONCESIONARIOS</p>
                    </div>
                    <div className="rowMenu">
                        <div className="colMenu">
                            <p className="colTitle">Ambato</p>
                            <p className="colTitle2">Matriz</p>
                            <p className="colItem">Av. Indoamérica Km 3-1/2</p>
                            <p className="colTitle2">Ficoa</p>
                            <p className="colItem">Av. Los Guaytambos</p>
                            <p className="colTitle">Machala</p>
                            <p className="colItem">Av 25 de junio km 3 y medio vía a pasajemax</p>
                            <p className="colTitle">Ibarra</p>
                            <p className="colItem">Panamericana Sur Km1 y calle los Andes</p>
                            <p className="colTitle">Manta</p>
                            <p className="colItem">Vía Manta- Rocafuerte - Sin Calle Secundaria, Vía al Aeropuerto</p>
                        </div>

                        <div className="colMenu">
                            <p className="colTitle">Quito</p>
                            <p className="colTitle2">Carapungo</p>
                            <p className="colItem">Av. Simón Bolivar S/N diagonal al Supermercado Santa María.</p>
                            <p className="colItem">Av. General Rumiñahui 8-40 y Av. San Luis (frente al San Luis Shopping)</p>
                            <p className="colTitle2">Sur</p>
                            <p className="colItem">Av. Maldonado y Romero Barberis</p>
                            <p className="colTitle2">El Colibrí</p>
                            <p className="colItem">E35 y Av. General Pintag</p>
                            <p className="colTitle2">Galo Plaza</p>
                            <p className="colItem">Av. Galo Plaza Lasso N66-106 y De las Avellanas</p>
                        </div>

                        <div className="colMenu">
                            <p className="colTitle">Guayaquil</p>
                            <p className="colTitle2">Samborondón</p>
                            <p className="colItem">Samborondón, vía el puente alterno norte, Ofc. Almax 2.</p>
                            <p className="colTitle2">Norte</p>
                            <p className="colItem">Autopista. Narcisa. De Jesús km1. Junto. a ATM. Agencia de tránsito municipal</p>
                            <p className="colTitle">Cuenca</p>
                            <p className="colItem">Av. Gil Ramírez Dávalos 3-80 y Mario Rizzini (Junto a la Gasolinera Primax)</p>
                        </div>

                        <div className="colMenu">
                            <p className="colTitle">Riobamba</p>
                            <p className="colItem">Av. Lizarzaburu entre Monseñor L. Proaño y Padre M. Orozco</p>
                        </div>
                    </div>
                </div>
            </li>

            <li>
                <a href="#" option="compania">COMPAÑÍA</a>
                <div className="item-level-6 nav-header nav-header-h" >
                    <div className="headerSubMenu">
                        <div className="closeMovil" data-id="6"></div>
                        <p>COMPAÑÍA</p>
                    </div>
                    <div className="rowMenu">
                        <div className="colMenu">
                            <p className="colItem meniu"><NavLink to="/compania">Nosotros</NavLink></p> 
                            <a href="https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/r/crm/login" className="colItem meniu negro">bdc</a>
                            <p className="colItem meniu"><NavLink to="/trabaja-con-nosotros">Trabaja con nosotros</NavLink></p>
                        </div>
                    </div>
                </div>
            </li>

            <li className="item-level-8 linkContact">
            </li>

        </ul>

        <ul className="nav-links-h">
            <li><NavLink to="/">INICIO</NavLink></li>
            <li ><a className="sinotruk mouseA" option="sinotruk">CAMIONES</a></li>
            <li className="concesionarios camionesCss"><NavLink to="/concesionarios">CONCESIONARIOS</NavLink></li>
            <li className="companiaCss"><NavLink to="/compania">COMPAÑIA</NavLink></li>
        </ul>

        <div className="navSearch">
            <input className="search-nav-input" data-testid="search-input" type="search" title="Search" placeholder="Buscar..." id="global-search-input" aria-controls="searchNavSuggestions" aria-autocomplete="list" aria-expanded="false" aria-activedescendant="search-suggestion-null" role="combobox" aria-describedby="searchBoxHelpText" />
        </div>
    </nav>
} 