import { Helmet } from "react-helmet"
import BannerContacto from "../components/BannerContacto"
import Footer from "../components/Footer"
import NavBar from "../NavBar"
import NavBarTop from "../NavBarTop"
import env from '../config';
import React, { useState } from 'react';
import testimonial1 from '../../src/media/videos/Tes_Arnulfo.mp4'
import testimonial2 from '../../src/media/videos/Tes_Carmen.mp4'
import testimonial6 from '../../src/media/videos/testimonio.mov'
import testimonial3 from '../../src/media/videos/Tes_Jussi.mp4'
import testimonial4 from '../../src/media/videos/Tes_Luis.mp4'
import testimonial5 from '../../src/media/videos/Tes_Oscar.mp4'
import testimonial7 from '../../src/media/videos/Tes_Tatiana.mp4'

import foto1 from '../../src/media/fotos/arnulfo.jpg'
import foto2 from '../../src/media/fotos/carmen.jpg'
import foto3 from '../../src/media/fotos/yuzzy.jpg'
import foto4 from '../../src/media/fotos/Luis.jpg'
import foto5 from '../../src/media/fotos/oscar.jpg'
import foto6 from '../../src/media/fotos/tatiana.jpg'

let urlMedia = env.url

function Testimonios() {

const [testimonialData , setTestimonialData] = useState(      {
    cargo: 'Vicepresidente de Fedetranscarga',
    name: 'Persona 1',
    video: testimonial1,
    imagen: foto1
  }) 


    const videoData = [
      {
        name: 'Dr. Arnulfo Cuervo',
        cargo: 'Vpdte. Fedetranscarga',
        video: testimonial1,
        imagen: foto1,
      },
      {
        name: 'Carmen Elena',
        cargo: 'Servicusiana SAS',
        video: testimonial2,
        imagen: foto2,
      },
      {
        name: 'Yuzzy Bermudez',
        cargo: 'JB Ingeniería',
        video: testimonial3,
        imagen: foto3,
      },
      {
        name: 'Luís Alberto Cortez',
        cargo: 'Dolphin Express S A',
        video: testimonial4,
        imagen: foto4,
      },
      {
        name: 'Oscar Porras',
        cargo: 'JB Ingeniería',
        video: testimonial5,
        imagen: foto5,
      },
      {
        name: 'Tatiana Sandoval',
        cargo: 'Servicusiana SAS',
        video: testimonial7,
        imagen: foto6,
      },
    ];
  


    console.log(testimonialData)
    
    
  

    return (
        <>
            <Helmet>
                <title>Testimonios Sinotruk | Vehicentro</title>
                <meta name="description" content="Experiencia sinotruk" />
            </Helmet>
            <NavBarTop />
            <div className="containerPc2">
                <h1 className="tituloTestimionios">TESTIMONIOS DELEGACIÓN COLOMBIANA EN CHINA</h1>
                
                <div className="contenedorGeneralTestimoniales2">
                    <div className="cardsColombia">
                    {
                            videoData.map(testimonial =>(
                                <div className="cardInvidivual">
                                  <div className="head">
                                    <div className="imagen-redonda">
                                      <img className="imagenTestimonio" src={testimonial.imagen}/>
                                    </div>
                                  </div>
                                    <div className="bodyCard">
                                      <p>{testimonial.name}</p>
                                      <p>{testimonial.cargo}</p>
                                      <button
                                      onClick={()=>{setTestimonialData({name: testimonial.name, video: testimonial.video})}}
                                      >
                                          Ver Testimonial
                                      </button>
                                    </div>
                                </div>
                            
                            ))
                        }
                    </div>
                
                    <div className="contenedorVideo">
                        <video className="videoTestimonial" autoPlay muted controls playsInline preload="metadata" src={testimonialData.video + "#t=0.1"}></video>
                    </div>
                </div>
            </div>
            <div className="containerResposive">
                     <h1 className="tituloTestimionios">TESTIMONIOS DELEGACIÓN COLOMBIANA EN CHINA</h1>   
                    <div className="contenedorVideo">
                        <video className="videoTestimonial" autoPlay loop muted controls playsInline preload="metadata" src={testimonial1 + "#t=0.1"}></video>
                        <video className="videoTestimonial" autoPlay loop muted controls playsInline preload="metadata" src={testimonial6 + "#t=0.1"}></video>
                        <video className="videoTestimonial" autoPlay loop muted controls playsInline preload="metadata" src={testimonial3 + "#t=0.1"}></video>
                        <video className="videoTestimonial" autoPlay loop muted controls playsInline preload="metadata" src={testimonial4 + "#t=0.1"}></video>
                        <video className="videoTestimonial" autoPlay loop muted controls playsInline preload="metadata" src={testimonial5 + "#t=0.1"}></video>
                        <video className="videoTestimonial" autoPlay loop muted controls playsInline preload="metadata" src={testimonial7 + "#t=0.1"}></video>
                    </div>
            </div>
            
   
            <BannerContacto />
            <Footer />
        </>
    )
}



export default Testimonios