import NavBarTop from "../NavBarTop"
import FormContact from "../components/FormContact";
import BannerContacto from "../components/BannerContacto";
import Footer from "../components/Footer";
import '../stylesIn.css';
import { useParams } from "react-router";
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import '../slider.css';
import { useEffect, useState } from "react";
import '../modalContact.css';
import FormContactFicha from "../components/FormContactFicha";
import { Helmet } from "react-helmet";
import env from '../config';
import precios from '../precios';

let imagen
let images
let motor
let caracteristicas
let cabina
let marcas
let internas1
let internas2
let internas2b
let internas1b
let internas
let medidas
let medidasMovil
let audio_motor
let garantia
let textoMotor1
let textoMotor2
let textoMotor3
let precio
let contenedorMarcas
let tecnologia
let slide3
let slide3b
let contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
let nombreCamion
let bdc
let camionSerie
let nombreSerie
let cuotas
let bdcFicha = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEBFICHTEC"
let urlMedia = env.url
let euro

function Camion() {

    const [modalFormIsOpen, setFormIsOpen] = useState(false);


    useEffect(() => {
        const motorPlayer = document.getElementById('audioMotor')
        motorPlayer.src = audio_motor

        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                closeFormContact()
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    function openFormContact() {
        setFormIsOpen(true)
    }

    function closeFormContact() {
        setFormIsOpen(false)
    }


    //Comparacion paginas
    switch ((useParams("id").id)) {

        //24 toneladas
        case "TX-350-4x2-Minimula":
            euro = urlMedia + "euro6.png"
            nombreCamion = "TX 350 4x2 Minimula"
            camionSerie = "TX 350 4x2 Minimula"
            nombreSerie = "Serie Tx"
            bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOTX350"
            images = [
                //urlMedia + "24-toneladas/camion-blanco-sinotruk-de-24-toneladas-ecuador.webp",
                urlMedia + "tx350/350Unidades.jpg"
            ];
            textoMotor1 = ""
            textoMotor2 = "TX 350 Minimula 4x2"
            textoMotor3 = "Ficha técnica"
            precio = precios.tx_minimmula
            cuotas = precios.iva
            motor = urlMedia + "minimula/minimulaMotor.jpg"
            garantia = urlMedia + "24-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "tecnologiaAlemanaLogoNew.png"
            caracteristicas = [
                urlMedia + "tx350/transtTX350.png",
                urlMedia + "24-toneladas/camion-de-24-toneladas-cilindraje.webp",
                urlMedia + "FRENO-1.png",
                urlMedia + "350Peso.png"
            ]
            cabina = urlMedia + "tx350/Cabina.jpg"
            marcas = urlMedia + "24-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "tx350/Volante.jpg",
            ];
            internas2 = [
                urlMedia + "tx350/Palanca350.jpg"
            ];
            internas1b = [
                urlMedia + "tx350/Litera.jpg",
            ];
            internas2b = [
                urlMedia + "17000/Aire-AC.jpg",
            ];

            medidas = urlMedia + "medidas/minimulaMedidas.webp"
            medidasMovil = urlMedia + "medidas/minimulaMedidasCelu.webp"
            audio_motor = urlMedia + "motorColombia.mp3"

            internas = internas1.concat(internas2)
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            contenedorVidaUtil = <div className="ajustetextovidautil"><p>Disponible en AMT</p></div>

            internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
            //loop slider

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            contenedorMarcas = <div style={{ display: `contents` }} >
                {internas1b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3}
                        </Fade>
                    </div>
                }
                {internas1b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas1b[0]} alt="" />
                    </div>
                }

                {internas2b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3b}
                        </Fade>
                    </div>
                }
                {internas2b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas2b[0]} alt="" />
                    </div>
                }
            </div>

            contenedorVidaUtil = <div className="banner"><img src={urlMedia + "3.5-toneladas/homologado.webp"} /></div>


            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            break;

        //28 toneladas
        case "TX-400-4x2-Super-Minimula":
            euro = urlMedia + "euro6.png"
            nombreCamion = "TX 400 4x2 Súper Minimula"
            camionSerie = "TX 400 4x2 Súper Minimula"
            nombreSerie = "Serie Tx"
            bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOTX400"
            images = [
                //urlMedia + "24-toneladas/camion-blanco-sinotruk-de-24-toneladas-ecuador.webp",
                urlMedia + "tx400/400Unidades.jpg"
            ];
            textoMotor1 = ""
            textoMotor2 = "TX 400 4x2 Súper Minimula"
            textoMotor3 = "Ficha técnica"
            precio = precios.tx_superMinimula
            cuotas = precios.iva
            motor = urlMedia + "tx400/MOTOR-TX-400.jpg"
            garantia = urlMedia + "24-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "tecnologiaAlemanaLogoNew.png"
            caracteristicas = [
                urlMedia + "45-toneladas/camion-de-45-toneladas-potencia-430hp.webp",
                urlMedia + "24-toneladas/camion-de-24-toneladas-cilindraje.webp",
                urlMedia + "FRENO-1.png",
                urlMedia + "400Peso.png"
            ]
            cabina = urlMedia + "tx400/cabina400.jpg"
            marcas = urlMedia + "24-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "tx400/tx400interna1.jpg",
            ];
            internas2 = [
                urlMedia + "tx400/tx400interna2.jpg",
            ];
            internas1b = [
                urlMedia + "tx400/tx400interna3.jpg",
            ];
            internas2b = [
                urlMedia + "tx400/tx400interna4.jpg",
            ];
            medidas = urlMedia + "medidas/superMinimulaMedidas.webp"
            medidasMovil = urlMedia + "medidas/superMinimulaMedidasCelu.webp"
            audio_motor = urlMedia + "motorColombia.mp3"

            internas = internas1.concat(internas2)

            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
            //loop slider

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            contenedorMarcas = <div style={{ display: `contents` }} >
                {internas1b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3}
                        </Fade>
                    </div>
                }
                {internas1b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas1b[0]} alt="" />
                    </div>
                }

                {internas2b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3b}
                        </Fade>
                    </div>
                }
                {internas2b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas2b[0]} alt="" />
                    </div>
                }
            </div>

            contenedorVidaUtil = <div className="banner"><img src={urlMedia + "3.5-toneladas/homologado.webp"} /></div>


            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            break;

        //42 toneladas
        case "G7-540-Tracto-AMT":
            euro = urlMedia + "euro6.png"
            console.log('48 ton')
            nombreCamion = "G7 540 Tracto AMT"
            camionSerie = "G7 540 Tracto AMT"
            nombreSerie = "Serie G7"
            bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOG7540AMT"
            images = [
                //urlMedia + "48-toneladas/portadac7h48.jpg",
                urlMedia + "g7/g7Unidades.jpg"
            ];
            textoMotor1 = ""
            textoMotor2 = "G7 540 Tracto AMT"
            textoMotor3 = "Ficha técnica"
            precio = precios.g7_540
            cuotas = precios.iva
            motor = urlMedia + "g7/g7Motor.jpg"
            garantia = urlMedia + "48-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "tecnologiaAlemanaLogoNew.png"
            caracteristicas = [
                urlMedia + "45-toneladas/camion-de-45-toneladas-potencia-430hp.webp",
                urlMedia + "48-toneladas/camion-de-48-toneladas-cilindraje.webp",
                urlMedia + "FRENO-1.png",
                urlMedia + "540Peso.png"
            ]
            cabina = urlMedia + "g7/CabinaG7540.jpg"
            marcas = urlMedia + "48-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "g7/g7Interna1.jpg",
            ];
            internas2 = [
                urlMedia + "g7/g7Interna2.jpg",
            ];
            internas1b = [
                urlMedia + "g7/g7Interna3.jpg",
            ];
            internas2b = [
                urlMedia + "g7/g7Interna4.jpg",
            ];
            medidas = urlMedia + "medidas/g7Medidas.webp"
            medidasMovil = urlMedia + "medidas/g7MedidasCelu.webp"
            audio_motor = urlMedia + "motorColombia.mp3"

            internas = internas1.concat(internas2)

            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>
            contenedorVidaUtil = <div className="ajustetextovidautil"><p>Disponible en AMT</p></div>

            internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
            //loop slider

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            contenedorMarcas = <div style={{ display: `contents` }} >
                {internas1b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3}
                        </Fade>
                    </div>
                }
                {internas1b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas1b[0]} alt="" />
                    </div>
                }

                {internas2b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3b}
                        </Fade>
                    </div>
                }
                {internas2b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas2b[0]} alt="" />
                    </div>
                }
            </div>

            contenedorVidaUtil = <div className="banner"><img src={urlMedia + "3.5-toneladas/homologado.webp"} /></div>


            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            break;

        default:
            break;
    }





    switch ((useParams("id").id)) {

        case "TX-350-4x2-Minimula":
            imagen = urlMedia + "minimula/minimulaHero.jpg"
            break;

        case "TX-400-4x2-Super-Minimula":
            imagen = urlMedia + "home/familiatracto4x26x4bIEN.webp"
            break;

        case "G7-540-Tracto-AMT":
            imagen = urlMedia + "home/familiag7.webp"
            break;

        default:
            break;
    }

    //loop primer slide
    let slide1 = images.map((image) =>
        <div className="each-slide-effect">
            <div style={{ 'backgroundImage': `url(${image})`, height: `41vw` }}>
            </div>
        </div>
    );
    //loop segundo slide

    let slide2 = internas1.map((interna) =>
        <div className="each-slide-effect" >
            <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
            </div>
        </div>
    );

    let slide2b = internas2.map((interna) =>
        <div className="each-slide-effect" >
            <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
            </div>
        </div>
    );
    //loop slide movil
    let slide2c = internas.map((interna) =>
        <div className="each-slide-effect" >
            <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
            </div>
        </div>
    );

    // ---- PÁGINA ----
    return <>
        <Helmet>
            <title>{nombreCamion} - Vehicentro</title>
            <meta name="description" content={'Venta del ' + nombreCamion + ' Sinotruk de la ' + nombreSerie + ' en colombia'} />
            <meta name="keywords" content="tractos, tracto, venta de tractos, tractos en colombia, venta de tracto, tracto en colombia, G7 540 Tracto AMT, TX 400 4x2 Súper Minimula, "></meta>
            <meta name="robots" content="index, follow"></meta>
        </Helmet>

        <NavBarTop />

        <div className="boxesIni posRelative content2">
            <div className="boxRight">
                {images.length > 1 &&
                    <Slide>
                        {slide1}
                    </Slide>
                }
                {images.length == 1 &&
                    <img src={images[0]} alt="" />
                }
            </div>
            <div className="boxLeft playMotor contenedorTextoSobrepuesto">
                <img src={motor} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                <div className="boxText flex-table row textoSobrepuesto">
                    <div onClick={openFormContact} className="flex-row textoMotor1">{textoMotor1}</div>
                    <div onClick={openFormContact} className="flex-row textoMotor2"><h1>{`${textoMotor2}`}</h1></div>
                    <div onClick={openFormContact} className="flex-row textoMotor3">{textoMotor3}</div>
                    <div onClick={openFormContact} className="flex-row textoMotorFlecha"><img src={urlMedia + "arrow-rojo.png"} alt="arrow" /></div>
                    <div onClick={openFormContact} className="flex-row textoMotor4">Escucha tu motor</div>
                </div>
                <div className="boxPlayerMotor ">
                    <audio id="audioMotor" controls type="audio/mpeg"></audio>
                </div>
            </div>

        </div>

        <div className="contentProduct">

            <div className="marcasbg textoImagenesProducto" >

                <div className="boxRightHeader  half text_icon_left" >
                    <p className='vidaUtil'>DESDE</p>
                    <p className='precioCamiones' >{precio}</p>
                    <p className='cuotas'>{cuotas}</p>
                </div>

                <div className="boxRightHeader  half icon_center" >
                    <div className="banner"><img src={tecnologia} /></div>
                </div>

                <div className="boxRightHeader  half text_icon_center" >
                    <div className="banner"><img src={euro} /></div>
                </div>

                <div className="boxRightHeader  half icon_right">
                    <div className="banner"><img src={garantia} /></div>
                </div>

            </div>


            <div className="boxesIni posRelative partesbg">

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[0]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[1]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[2]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[3]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

            </div>
            <div className="privaty">
                <div className="texto">
                    <p>Las fotos relacionadas en esta página son de referencia, algunos accesorios son parte de la ambientación fotográfica o de otras versiones del vehículo. Las ilustraciones muestran también equipos opcionales como carrocerías y equipos no pertenecientes a la configuración de serie para Colombia. Tras el cierre de la publicación (01/09/2023) pueden haberse producido cambios o modificaciones en el diseño y/o equipamiento del producto, las cuales pedimos verificar en la red de concesionarios. Para mayor información sobre el portafolio de productos, precios, garantía y financiación visite nuestra red de concesionarios autorizada de Vehicentro Colombia S.A.</p>
                </div>
            </div>

            <div className="boxesIni posRelative">
                <div className="marcasbg">
                    <img src={cabina} width="1500" height="1500" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="marcasbg" style={{ display: `flex`, 'flexWrap': `wrap` }}>
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        {/* <Fade > */}
                        {slide2}
                        {/* </Fade> */}
                    </div>
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }} >
                        {/* <Fade > */}
                        {slide2b}
                        {/* </Fade> */}
                    </div>
                    <div className="boxRightHeader  half responsive" style={{ width: `100%` }} >
                        <Fade >
                            {slide2c}
                        </Fade>
                    </div>
                    {contenedorMarcas}

                </div>


            </div>
            <div className="bannerMedidas noResponsive"><img src={medidas} /></div>
            <div className="bannerMedidas responsive"><img src={medidasMovil} /></div>
        </div>


        <div className="boxesIni posRelative centrado">
            <div className="centrado">
                <img src={imagen} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
            </div>
            <div>
                <FormContact url={bdc} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} />
            </div>
        </div>
        <BannerContacto url={bdc} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} />
        <Footer />

        {modalFormIsOpen &&
            <FormContactFicha url={bdcFicha} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} close={closeFormContact} />
        }
    </>
}

export default Camion
