import env from '../config';
let urlMedia = env.url

export const CenterContact = () => {
    return (
      <div className="formaContacto">
      <a className="forma1">
          <img className="formaImg" src={urlMedia + "/contacto/whats.png"} alt="" title="" />
          <p className="moreP2">WHATSAPP</p>
          <p>Haz <a href="https://wa.me/573106392803?text=Hola,%20Vehicentro%20Colombia">clic aquí</a> para empezar a chatear</p>
      </a>
      <div className="forma2">
          <img className="formaImg" src={urlMedia + "/contacto/telefono.png"} alt="" title="" />
          <p className="moreP2">TELÉFONO</p>
          <p>Haz <a href="tel:+573106392803">clic aquí</a> para llamarnos al +57 310 6392803</p>
      </div>
      <div className="forma3">
          <img className="formaImg" src={urlMedia + "/contacto/email.png"} alt="" title="" />
          <p className="moreP2">E-MAIL</p>
          <p>Haz <a href="mailto:contactoco@vehicentro.com?subject=Consulta%20Web&body=Hola,%20Vehicentro">clic aquí</a> para contactarnos a contactoco@vehicentro.com</p>
      </div>
  
  </div>
    )
  }
