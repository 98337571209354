import NavBarTop from "../NavBarTop"
import FormContact from "../components/FormContact";
import BannerContacto from "../components/BannerContacto";
import Footer from "../components/Footer";
import '../stylesIn.css';
import { useParams } from "react-router";
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import '../slider.css';
import { useEffect, useState } from "react";
import '../modalContact.css';
import FormContactFicha from "../components/FormContactFicha";
import { Helmet } from "react-helmet";
import env from '../config';
import precios from '../precios';

let imagen
let images
let motor
let caracteristicas
let cabina
let marcas
let internas1
let internas2
let internas1b
let internas2b
let internas
let medidas
let medidasMovil
let audio_motor
let garantia
let textoMotor1
let textoMotor2
let textoMotor3
let precio
let contenedorMarcas
let tecnologia
let slide3
let slide3b
let contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
let nombreCamion
let bdc
let bdcFicha = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEBFICHTEC"
let nombreSerie
let camionSerie
let cuotas
let nombreBusqueda
let urlMedia = env.url
let euro

function Camion() {

    const [modalFormIsOpen, setFormIsOpen] = useState(false);


    useEffect(() => {
        const motorPlayer = document.getElementById('audioMotor')
        motorPlayer.src = audio_motor

        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                closeFormContact()
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    function openFormContact() {
        setFormIsOpen(true)
    }

    function closeFormContact() {
        setFormIsOpen(false)
    }

    //Comparacion paginas
    switch ((useParams("id").id)) {
        case "TX-250-Chasis-Corto-Volqueta-Sencilla":
            euro = urlMedia + "euro6.png"
            console.log('12 toneladas')
            nombreCamion = "TX 250 Chasís Corto Volqueta Sencilla"
            camionSerie = "TX 250 Chasís Corto Volqueta Sencilla"
            nombreSerie = "Serie Tx Camiones Sencillos"
            bdc = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEB12TT5G1167"
            images = [
                urlMedia + "12-toneladas/camion-blanco-sinotruk-de-12-toneladas-ecuador.webp",
                urlMedia + "12-toneladas/camion-blanco-sinotruk-de-12-toneladas-ecuador-de-frente.webp"
            ];
            textoMotor1 = "240 HP"
            textoMotor2 = "Modelo T5G-1167 - 12 Ton"
            textoMotor3 = "Ficha técnica"
            precio = precios.doce
            cuotas = `Cuotas desde: ${precios.doce_cuota}`
            motor = urlMedia + "12-toneladas/motor-sinotruk-de-camion-de-12-toneladas-ecuador.webp"
            garantia = urlMedia + "12-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "2-tecnologia-alemana_logo.png"
            caracteristicas = [
                urlMedia + "12-toneladas/camion-de-12-toneladas-potencia-240hp.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-6-cilindros-en-linea.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-freno-a-las-valvulas.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-inyeccion-common-rail.webp"
            ]
            cabina = urlMedia + "frs/Cabina.jpg"
            marcas = urlMedia + "12-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "frs/Radio.jpg",
            ];
            internas2 = [
                urlMedia + "frs/Volante.jpg",
            ];
            internas1b = [
                urlMedia + "frs/Palanca.jpg",
            ];
            internas2b = [
                urlMedia + "frs/Vidrios.jpg",
            ];
            medidas = urlMedia + "12-toneladas/dimensiones-de-camion-de-12-toneladas-sinotruk.webp"
            medidasMovil = urlMedia + "12-toneladas/dimensiones-de-camion-de-12-toneladas-sinotruk-responsive.webp"
            audio_motor = urlMedia + "12-toneladas/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            break;

        //9 toneladas
        case "camion-40500":
            euro = urlMedia + "euro6.png"
            nombreCamion = "Camión 40500"
            camionSerie = "40500"
            nombreSerie = "Serie T5G"
            bdc = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEBVHCR9T1167"
            console.log('9 toneladas')
            images = [
                urlMedia + "9-toneladas/camion-blanco-sinotruk-de-9-toneladas-ecuador.webp",
                urlMedia + "9-toneladas/camion-blanco-sinotruk-de-9-toneladas-ecuador-de-frente.webp"
            ];
            textoMotor1 = "210 HP"
            textoMotor2 = "Modelo T5G-1167 - 9 Ton"
            textoMotor3 = "Ficha técnica"
            precio = precios.nueve
            cuotas = `Cuotas desde: ${precios.nueve_cuota}`
            motor = urlMedia + "9-toneladas/motor-sinotruk-de-camion-de-9-toneladas-ecuador.webp"
            garantia = urlMedia + "9-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "9-toneladas/tecnologia-alemana.webp"
            caracteristicas = [
                urlMedia + "9-toneladas/camion-de-9-toneladas-potencia-210hp.webp",
                urlMedia + "9-toneladas/camion-de-9-toneladas-4-cilindros-en-linea.webp",
                urlMedia + "9-toneladas/camion-de-9-toneladas-freno-a-camion-de-10-toneladas-freno-a-las-valvulas.webp",
                urlMedia + "9-toneladas/camion-de-9-toneladas-inyeccion-common-rail.webp"
            ]
            cabina = urlMedia + "9-toneladas/cabina-de-camion-de-9-toneladas-sinotruk.webp"
            marcas = urlMedia + "9-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "9-toneladas/volante-deportivo-de-camion-de-9-toneladas.webp",
                urlMedia + "9-toneladas/palanca-de-cambios-de-camion-de-9-toneladas.webp",
                urlMedia + "9-toneladas/asiento-neumatico.webp",
                urlMedia + "9-toneladas/eleva-vidrios-electricos.webp"
            ];
            internas2 = [
                urlMedia + "9-toneladas/climatizador.webp",
                urlMedia + "9-toneladas/radio-touch-mp5.webp",
                urlMedia + "9-toneladas/cabina.webp",
                urlMedia + "9-toneladas/portavasos.webp"
            ];

            medidas = urlMedia + "9-toneladas/dimensiones-de-camion-de-9-toneladas-sinotruk.webp"
            medidasMovil = urlMedia + "9-toneladas/dimensiones-de-camion-de-9-toneladas-sinotruk-responsive.webp"
            audio_motor = urlMedia + "9-toneladas/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            break;

        //12 toneladas
        case "TX-250-4x2-PBV-17.000-Chasis":
            euro = urlMedia + "euro6.png"
            console.log('12 toneladas')
            nombreCamion = "TX 250 4x2 PBV 17.000 Chasís"
            camionSerie = "TX 250 4x2 PBV 17.000 Chasís"
            nombreSerie = "Serie Tx"
            bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOTX250"
            images = [
                //urlMedia + "12-toneladas/camion-blanco-sinotruk-de-12-toneladas-ecuador.webp",
                urlMedia + "17000/250Unidades.jpg"
            ];
            textoMotor1 = ""
            textoMotor2 = "TX 250 4x2 PBV 17.000 Chasís"
            textoMotor3 = "Ficha técnica"
            precio = precios.tx_17000
            cuotas = precios.iva
            motor = urlMedia + "17000/motor17000.jpg"
            garantia = urlMedia + "12-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "tecnologiaAlemanaLogoNew.png"
            caracteristicas = [
                urlMedia + "12-toneladas/camion-de-12-toneladas-potencia-240hp.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-6-cilindros-en-linea.webp",
                urlMedia + "newFreno.png",
                urlMedia + "250Peso.png"
            ]
            cabina = urlMedia + "17000/Cabina.jpg"
            marcas = urlMedia + "12-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "17000/Vidrios.jpg",
            ];
            internas2 = [
                urlMedia + "17000/Palanca.jpg",
            ];
            internas1b = [
                urlMedia + "17000/Aire-AC.jpg",
            ];
            internas2b = [
                urlMedia + "17000/Volante.jpg",
            ];
            medidas = urlMedia + "medidas/17000Medidas.webp"
            medidasMovil = urlMedia + "medidas/17000MedidasCelu.webp"
            audio_motor = urlMedia + "12-toneladas/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
//loop slider

slide3 = internas1b.map((interna) =>
    <div className="each-slide-effect" >
        <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
        </div>
    </div>
);

slide3b = internas2b.map((interna) =>
    <div className="each-slide-effect" >
        <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
        </div>
    </div>
);

contenedorMarcas = <div style={{ display: `contents` }} >
    {internas1b.length > 1 &&
        <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
            <Fade >
                {slide3}
            </Fade>
        </div>
    }
    {internas1b.length == 1 &&
        <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
            <img src={internas1b[0]} alt="" />
        </div>
    }

    {internas2b.length > 1 &&
        <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
            <Fade >
                {slide3b}
            </Fade>
        </div>
    }
    {internas2b.length == 1 &&
        <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
            <img src={internas2b[0]} alt="" />
        </div>
    }
</div>

contenedorVidaUtil = <div className="banner"><img src={urlMedia + "3.5-toneladas/homologado.webp"} /></div>


slide3 = internas1b.map((interna) =>
    <div className="each-slide-effect" >
        <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
        </div>
    </div>
);

slide3b = internas2b.map((interna) =>
    <div className="each-slide-effect" >
        <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
        </div>
    </div>
);

            break;

        case "TX-250-Chasís-Largo":
            euro = urlMedia + "euro6.png"
            console.log('12 toneladas')
            nombreCamion = "TX 250 Chasís Largo"
            camionSerie = "TX 250 Chasís Largo"
            nombreSerie = "Serie Tx Camiones Sencillos"
            bdc = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEB12TT5G1167"
            images = [
                urlMedia + "12-toneladas/camion-blanco-sinotruk-de-12-toneladas-ecuador.webp",
                urlMedia + "12-toneladas/camion-blanco-sinotruk-de-12-toneladas-ecuador-de-frente.webp"
            ];
            textoMotor1 = "240 HP"
            textoMotor2 = "Modelo T5G-1167 - 12 Ton"
            textoMotor3 = "Ficha técnica"
            precio = precios.doce
            cuotas = `Cuotas desde: ${precios.doce_cuota}`
            motor = urlMedia + "12-toneladas/motor-sinotruk-de-camion-de-12-toneladas-ecuador.webp"
            garantia = urlMedia + "12-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "12-toneladas/tecnologia-alemana.webp"
            caracteristicas = [
                urlMedia + "12-toneladas/camion-de-12-toneladas-potencia-240hp.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-6-cilindros-en-linea.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-freno-a-las-valvulas.webp",
                urlMedia + "12-toneladas/camion-de-12-toneladas-inyeccion-common-rail.webp"
            ]
            cabina = urlMedia + "12-toneladas/cabina-de-camion-de-12-toneladas-sinotruk.webp"
            marcas = urlMedia + "12-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "12-toneladas/volante-deportivo-de-camion-de-12-toneladas.webp",
                urlMedia + "12-toneladas/palanca-de-cambios-de-camion-de-12-toneladas.webp",
                urlMedia + "12-toneladas/asiento-neumatico-dos.webp",
                urlMedia + "12-toneladas/eleva-vidrios-electricos.webp",
                urlMedia + "12-toneladas/radio-touch-mp5.webp"
            ];
            internas2 = [
                urlMedia + "12-toneladas/climatizador.webp",
                urlMedia + "12-toneladas/radio-touch-mp5.webp",
                urlMedia + "12-toneladas/cabina.webp",
                urlMedia + "12-toneladas/portavasos.webp"
            ];

            medidas = urlMedia + "12-toneladas/dimensiones-de-camion-de-12-toneladas-sinotruk.webp"
            medidasMovil = urlMedia + "12-toneladas/dimensiones-de-camion-de-12-toneladas-sinotruk-responsive.webp"
            audio_motor = urlMedia + "12-toneladas/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            break;

        //13 toneladas 330hp
        case "camion-13-toneladas-t5g-1167-330hp":
            euro = urlMedia + "euro6.png"
            console.log('13 toneladas 330Hp')
            nombreCamion = "Camión de 13 toneladas 330Hp | T5G-1167"
            camionSerie = "T5G 1167 / 13 TON (330HP)"
            nombreSerie = "Serie T5G"
            bdc = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/Web13TOVDT5G1167"
            images = [
                urlMedia + "13-toneladas-330/camion-blanco-sinotruk-de-13-toneladas-ecuador.webp",
                urlMedia + "13-toneladas-330/camion-blanco-sinotruk-de-13-toneladas-de-frente-ecuador.webp"
            ];
            textoMotor1 = "330 HP"
            textoMotor2 = "Modelo T5G-1167 - 13 Ton"
            textoMotor3 = "Ficha Técnica"
            precio = precios.trece_330
            cuotas = `Cuotas desde: ${precios.trece_330_cuota}`
            motor = urlMedia + "13-toneladas-330/motor-sinotruk-de-camion-de-13-toneladas-ecuador.webp"
            garantia = urlMedia + "13-toneladas-330/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "13-toneladas-330/tecnologia-alemana.webp"
            caracteristicas = [
                urlMedia + "13-toneladas-330/camion-de-13-toneladas-potencia-330hp.webp",
                urlMedia + "13-toneladas-330/camion-de-13-toneladas-6-cilindros-en-linea.webp",
                urlMedia + "13-toneladas-330/camion-de-13-toneladas-freno-a-las-valvulas.webp",
                urlMedia + "13-toneladas-330/camion-de-13-toneladas-inyeccion-common-rail.webp"
            ]
            cabina = urlMedia + "13-toneladas-330/cabina-de-camion-de-13-toneladas-sinotruk.webp"
            marcas = urlMedia + "13-toneladas-330/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "13-toneladas-330/volante-deportivo-de-camion-de-13-toneladas.webp",
                urlMedia + "13-toneladas-330/palanca-de-cambios-de-camion-de-13-toneladas.webp",
                urlMedia + "13-toneladas-330/asiento-neumatico.webp",
                urlMedia + "13-toneladas-330/eleva-vidrios-electricos.webp",
                urlMedia + "13-toneladas-330/radio-touch-mp5.webp"
            ];
            internas2 = [
                urlMedia + "13-toneladas-330/climatizador.webp",
                urlMedia + "13-toneladas-330/radio-touch-mp5.webp",
                urlMedia + "13-toneladas-330/cabina.webp",
                urlMedia + "13-toneladas-330/portavasos.webp"
            ];
            medidas = urlMedia + "13-toneladas-330/dimensiones-de-camion-de-13-toneladas-sinotruk.webp"
            medidasMovil = urlMedia + "13-toneladas-330/dimensiones-de-camion-de-13-toneladas-sinotruk-responsive.webp"
            audio_motor = urlMedia + "13-toneladas-330/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            break;

        //18 toneladas
        case "camion-de-18-toneladas-t5g-1257":
            euro = urlMedia + "euro6.png"
            console.log('18 toneladas')
            nombreCamion = "Camión de 18 toneladas | T5G-1257"
            camionSerie = "T5G 1257 / 18 TON"
            nombreSerie = "Serie T5G"
            bdc = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEB18T1257"
            images = [
                urlMedia + "18-toneladas/camion-blanco-sinotruk-de-18-toneladas-ecuador.webp"
            ];
            textoMotor1 = "340 HP"
            textoMotor2 = "Modelo T5G-1257 - 18 Ton"
            textoMotor3 = "Ficha técnica"
            precio = precios.d_ocho
            cuotas = `Cuotas desde: ${precios.d_ocho_cuota}`
            motor = urlMedia + "18-toneladas/motor-sinotruk-de-camion-de-18-toneladas-ecuador.webp"
            garantia = urlMedia + "18-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "18-toneladas/tecnologia-alemana.webp"
            caracteristicas = [
                urlMedia + "18-toneladas/camion-de-18-toneladas-potencia-340hp.webp",
                urlMedia + "18-toneladas/camion-de-18-toneladas-6-cilindros-en-linea.webp",
                urlMedia + "18-toneladas/camion-de-18-toneladas-freno-a-las-valvulas.webp",
                urlMedia + "18-toneladas/camion-de-18-toneladas-inyeccion-common-rail.webp"
            ]
            cabina = urlMedia + "18-toneladas/cabina-de-camion-de-18-toneladas-sinotruk.webp"
            marcas = urlMedia + "18-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "18-toneladas/volante-deportivo-de-camion-de-18-toneladas.webp",
                urlMedia + "18-toneladas/palanca-de-cambios-de-camion-de-18-toneladas.webp",
                urlMedia + "18-toneladas/asiento-neumatico.webp",
                urlMedia + "18-toneladas/eleva-vidrios-electricos.webp",
                urlMedia + "18-toneladas/radio-touch-mp5.webp"
            ];
            internas2 = [
                urlMedia + "18-toneladas/eje.webp",
                urlMedia + "18-toneladas/climatizador.webp",
                urlMedia + "18-toneladas/radio-touch-mp5.webp",
                urlMedia + "18-toneladas/cabina.webp",
                urlMedia + "18-toneladas/portavasos.webp"
            ];
            medidas = urlMedia + "18-toneladas/dimensiones-de-camion-de-18-toneladas-sinotruk.webp"
            medidasMovil = urlMedia + "18-toneladas/dimensiones-de-camion-de-18-toneladas-sinotruk-responsive.webp"
            audio_motor = urlMedia + "18-toneladas/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            break;

        //19 toneladas
        case "camion-de-19-toneladas-t5g-1257":
            euro = urlMedia + "euro6.png"
            console.log('19 toneladas')
            nombreCamion = "Camión de 19 toneladas | T5G-1257"
            camionSerie = "T5G 1257 / 19.5 TON"
            nombreSerie = "Serie T5G"
            bdc = "https://bdc.vehicentro.com:9443/ords/ws_vehicentro/api/conexiones/wordpress/WEB19T1257"
            images = [
                urlMedia + "19-toneladas/camion-blanco-sinotruk-de-19-toneladas-ecuador.webp",
                urlMedia + "19-toneladas/camion-amarillo-sinotruk-de-19-toneladas-ecuador.webp"
            ];
            textoMotor1 = "340 HP"
            textoMotor2 = "Modelo T5G-1257"
            textoMotor3 = "Ficha técnica"
            precio = precios.d_nueve
            cuotas = `Cuotas desde: ${precios.d_nueve_cuota}`
            motor = urlMedia + "19-toneladas/motor-sinotruk-de-camion-de-19-toneladas-ecuador.webp"
            garantia = urlMedia + "19-toneladas/5-anos-de-garantia.webp"
            tecnologia = urlMedia + "19-toneladas/tecnologia-alemana.webp"
            caracteristicas = [
                urlMedia + "19-toneladas/camion-de-19-toneladas-potencia-340hp.webp",
                urlMedia + "19-toneladas/camion-de-19-toneladas-6-cilindros-en-linea.webp",
                urlMedia + "19-toneladas/camion-de-19-toneladas-freno-a-las-valvulas.webp",
                urlMedia + "19-toneladas/camion-de-19-toneladas-inyeccion-common-rail.webp"
            ]
            cabina = urlMedia + "19-toneladas/cabina-de-camion-de-19-toneladas-sinotruk.webp"
            marcas = urlMedia + "19-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "19-toneladas/volante-deportivo-de-camion-de-19-toneladas.webp",
                urlMedia + "19-toneladas/palanca-de-cambios-de-camion-de-19-toneladas.webp",
                urlMedia + "19-toneladas/asiento-neumatico.webp",
                urlMedia + "19-toneladas/eleva-vidrios-electricos.webp",
                urlMedia + "19-toneladas/radio-touch-mp5.webp"
            ];
            internas2 = [
                urlMedia + "19-toneladas/climatizador.webp",
                urlMedia + "19-toneladas/radio-touch-mp5.webp",
                urlMedia + "19-toneladas/cabina.webp",
                urlMedia + "19-toneladas/portavasos.webp"
            ];
            medidas = urlMedia + "19-toneladas/dimensiones-de-camion-de-19-toneladas-sinotruk.webp"
            medidasMovil = urlMedia + "19-toneladas/dimensiones-de-camion-de-19-toneladas-sinotruk-responsive.webp"
            audio_motor = urlMedia + "19-toneladas/motor.mp3"

            internas = internas1.concat(internas2)
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>

            break;

        //3.5 toneladas
        case "FRS-10.4-Ton-Super-Turbo":
            euro = urlMedia + "euro6.png"
            nombreCamion = "FRS 10.4 Ton Super Turbo"
            camionSerie = "FRS 10.4 Ton Super Turbo"
            nombreBusqueda = "FRS 10.4 Ton Super Turbo"
            nombreSerie = "Serie 100"
            bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCOFRS"
            images = [
                //urlMedia + "8-toneladas/camion-blanco-sinotruk-de-8-toneladas-ecuador.webp",
                urlMedia + "frs/frsUnidades.jpeg"
            ];
            textoMotor1 = ""
            textoMotor2 = "FRS 10.4 Ton Súper Turbo"
            textoMotor3 = "Ficha técnica"
            precio = precios.chasis_frs
            cuotas = precios.iva
            motor = urlMedia + "frs/frsMotor.jpg"
            garantia = urlMedia + "logo2Gara.png"
            tecnologia = urlMedia + "frs/cumminsLogo.png"
            caracteristicas = [
                urlMedia + "3.5-toneladas/camion-de-3.5-toneladas-potencia-87hp.webp",
                urlMedia + "8-toneladas/camion-de-8-toneladas-cilindraje.webp",
                urlMedia + "newFreno.png",
                urlMedia + "frsPeso.png"
            ]
            cabina = urlMedia + "frs/Cabina.jpg"
            marcas = urlMedia + "8-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "frs/Radio.jpg",
            ];
            internas2 = [
                urlMedia + "frs/Volante.jpg",
            ];
            internas1b = [
                urlMedia + "frs/Palanca.jpg",
            ];
            internas2b = [
                urlMedia + "frs/Vidrios.jpg",
            ];
            medidas = urlMedia + "medidas/frsMedidas.webp"
            medidasMovil = urlMedia + "medidas/frsMedidasCelu.webp"
            audio_motor = urlMedia + "8-toneladas/motor.mp3"

            internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
            //loop slider

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            contenedorMarcas = <div style={{ display: `contents` }} >
                {internas1b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3}
                        </Fade>
                    </div>
                }
                {internas1b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas1b[0]} alt={`Internas 1 ${nombreBusqueda}`} />
                    </div>
                }

                {internas2b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3b}
                        </Fade>
                    </div>
                }
                {internas2b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas2b[0]} alt={`Internas 2 ${nombreBusqueda}`} />
                    </div>
                }
            </div>

            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
          


            internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
            //loop slider

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            contenedorMarcas = <div style={{ display: `contents` }} >
                {internas1b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3}
                        </Fade>
                    </div>
                }
                {internas1b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas1b[0]} alt="" />
                    </div>
                }

                {internas2b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3b}
                        </Fade>
                    </div>
                }
                {internas2b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas2b[0]} alt="" />
                    </div>
                }
            </div>

            contenedorVidaUtil = <div className="banner"><img src={urlMedia + "3.5-toneladas/homologado.webp"} /></div>
            break;


        //3.6 toneladas
        case "NQS-9.9-Ton":
            euro = urlMedia + "euro6.png"
            nombreCamion = "NQS 9.9 Ton"
            camionSerie = "NQS 9.9 Ton"
            nombreSerie = "Serie 100"
            bdc = "https://bdcco.vehicentro.com:8443/ords/ws_vehicentro/api/conexiones/web/WEBVHCONQS"
            images = [
                // urlMedia + "8-toneladas/camion-blanco-sinotruk-de-8-toneladas-ecuador.webp",
                urlMedia + "nqs/nqsUnidades.jpg"
            ];
            textoMotor1 = ""
            textoMotor2 = "NQS 9.9 Ton"
            textoMotor3 = "Ficha técnica"
            precio = precios.chasis_nqs
            cuotas = precios.iva
            motor = urlMedia + "nqs/nqsMotor.jpg"
            garantia = urlMedia + "logo2Gara.png"
            euro = urlMedia + "euro6.png"
            tecnologia = urlMedia + "frs/cumminsLogo.png"
            caracteristicas = [
                urlMedia + "3.5-toneladas/camion-de-3.5-toneladas-potencia-87hp.webp",
                urlMedia + "8-toneladas/camion-de-8-toneladas-cilindraje.webp",
                urlMedia + "newFreno.png",
                urlMedia + "PESO-NQS2.png"
            ]
            cabina = urlMedia + "frs/Cabina.jpg"
            marcas = urlMedia + "8-toneladas/marcas-vehicentro.webp"
            internas1 = [
                urlMedia + "frs/Radio.jpg",
            ];
            internas2 = [
                urlMedia + "frs/Volante.jpg",
            ];
            internas1b = [
                urlMedia + "frs/Palanca.jpg",
            ];
            internas2b = [
                urlMedia + "frs/Vidrios.jpg",
            ];
            medidas = urlMedia + "medidas/nqsMedidas.webp"
            medidasMovil = urlMedia + "medidas/nqsMedidasCelu.webp"
            audio_motor = urlMedia + "8-toneladas/motor.mp3"

            

            contenedorMarcas = <div className="boxRightHeader  half" style={{ width: '100 %' }} >
                <img src={marcas} width="1500" height="750" className="slideMain" alt="Punto de Venta" />
            </div>
            contenedorVidaUtil = "Vida útil de más de 1.5 MILLONES de kilómetros"
            


            internas = ((internas1.concat(internas2)).concat(internas1b)).concat(internas2b)
            //loop slider

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            contenedorMarcas = <div style={{ display: `contents` }} >
                {internas1b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3}
                        </Fade>
                    </div>
                }
                {internas1b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas1b[0]} alt="" />
                    </div>
                }

                {internas2b.length > 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <Fade >
                            {slide3b}
                        </Fade>
                    </div>
                }
                {internas2b.length == 1 &&
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        <img src={internas2b[0]} alt="" />
                    </div>
                }
            </div>

            contenedorVidaUtil = <div className="banner"><img src={urlMedia + "3.5-toneladas/homologado.webp"} /></div>
         

            slide3 = internas1b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );

            slide3b = internas2b.map((interna) =>
                <div className="each-slide-effect" >
                    <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
                    </div>
                </div>
            );
            break;
        default:
            break;
    }




    switch ((useParams("id").id)) {

        case "TX-250-4x2-PBV-17.000-Chasis":
            imagen = urlMedia + "home/familiaSencillo.webp"
            break;

        case "FRS-10.4-Ton-Super-Turbo":
            imagen = urlMedia + "frs/frsHero.jpg"
            break;

        case "NQS-9.9-Ton":
            imagen = urlMedia + "home/familia100.webp"
            break;

        case "camion-de-6-toneladas-1067":
            imagen = urlMedia + "6-toneladas/camion-de-6-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-8-toneladas-1147":
            imagen = urlMedia + "8-toneladas/camion-de-8-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-de-9-toneladas":
            imagen = urlMedia + "9-toneladas/camion-de-9-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-10-toneladas-t5g-1167":
            imagen = urlMedia + "10-toneladas/camion-de-10-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-12-toneladas-t5g-1167":
            imagen = urlMedia + "12-toneladas/camion-de-12-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-13-toneladas-t5g-1167":
            imagen = urlMedia + "13-toneladas/camion-de-13-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-13-toneladas-t5g-1167-330hp":
            imagen = urlMedia + "13-toneladas-330/camion-de-13-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-de-18-toneladas-t5g-1257":
            imagen = urlMedia + "18-toneladas/camion-de-18-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-de-19-toneladas-t5g-1257":
            imagen = urlMedia + "19-toneladas/camion-de-19-toneladas-en-la-carretera-sinotruk.webp"
            break;

        case "camion-20-toneladas-1256":
            imagen = urlMedia + "20-toneladas/camion-de-20-toneladas-en-la-carretera-sinotruk.webp"
            break;

        default:
            break;
    }


    //loop primer slide
    let slide1 = images.map((image) =>
        <div className="each-slide-effect">
            <div style={{ 'backgroundImage': `url(${image})`, height: `41vw` }}>
            </div>
        </div>
    );
    //loop segundo slide

    let slide2 = internas1.map((interna) =>
        <div className="each-slide-effect" >
            <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
            </div>
        </div>
    );

    let slide2b = internas2.map((interna) =>
        <div className="each-slide-effect" >
            <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
            </div>
        </div>
    );
    //loop slide movil
    let slide2c = internas.map((interna) =>
        <div className="each-slide-effect" >
            <div style={{ 'backgroundImage': `url(${interna})`, height: `25vw` }}>
            </div>
        </div>
    );

    // ---- PÁGINA ----
    return <>
        <Helmet>
            <title>{nombreCamion} - Vehicentro</title>
            <meta name="description" content={'Venta del ' + nombreCamion + ' Sinotruk de la ' + nombreSerie + ' en Colombia'} />
            <meta name="keywords" content="camion, camiones, venta de camiones, camiones en Colombia"></meta>
            <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <NavBarTop />
        <div className="boxesIni posRelative content2">
            <div className="boxRight">
                {images.length > 1 &&
                    <Slide>
                        {slide1}
                    </Slide>
                }
                {images.length == 1 &&
                    <img src={images[0]} alt="" />
                }
            </div>
            <div className="boxLeft playMotor contenedorTextoSobrepuesto">
                <img src={motor} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                <div className="boxText flex-table row textoSobrepuesto">
                    <div onClick={openFormContact} className="flex-row textoMotor1">{textoMotor1}</div>
                    <div onClick={openFormContact} className="flex-row textoMotor2"><h1>{`${textoMotor2}`}</h1></div>
                    <div onClick={openFormContact} className="flex-row textoMotor3">{textoMotor3}</div>
                    <div onClick={openFormContact} className="flex-row textoMotorFlecha"><img src={urlMedia + "arrow-rojo.png"} alt="arrow" /></div>
                    <div onClick={openFormContact} className="flex-row textoMotor4">Escucha tu motor</div>
                </div>
                <div className="boxPlayerMotor ">
                    <audio id="audioMotor" controls type="audio/mpeg"></audio>
                </div>
            </div>

        </div>

        <div className="contentProduct">

            <div className="marcasbg textoImagenesProducto" >

                <div className="boxRightHeader  half text_icon_left" >
                    <p className='vidaUtil'>DESDE</p>
                    <p className='precioCamiones' >{precio}</p>
                    <p className='cuotas'>{cuotas}</p>
                </div>
                

                <div className="boxRightHeader  half icon_center moreSpace" >
                    <div className="banner"><img src={tecnologia} /></div>
                </div>

                <div className="boxRightHeader  half text_icon_center" >
                <div className="banner"><img src={euro} /></div>
                </div>

                <div className="boxRightHeader  half icon_right">
                    <div className="banner"><img src={garantia} /></div>
                </div>

            </div>
            <div className="boxesIni posRelative partesbg">

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[0]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[1]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[2]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

                <div className="caracteristicasProductos" >
                    <img src={caracteristicas[3]} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>

            </div>
            <div className="privaty">
                <div className="texto">
                <p>Las fotos relacionadas en esta página son de referencia, algunos accesorios son parte de la ambientación fotográfica o de otras versiones del vehículo. Las ilustraciones muestran también equipos opcionales como carrocerías y equipos no pertenecientes a la configuración de serie para Colombia. Tras el cierre de la publicación (01/09/2023) pueden haberse producido cambios o modificaciones en el diseño y/o equipamiento del producto, las cuales pedimos verificar en la red de concesionarios. Para mayor información sobre el portafolio de productos, precios, garantía y financiación visite nuestra red de concesionarios autorizada de Vehicentro Colombia S.A.</p>
                </div>
            </div>


           
         
            <div className="boxesIni posRelative">
                <div className="marcasbg">
                    <img src={cabina} width="1500" height="1500" className="slideMain" alt={`cabina ${nombreBusqueda}`} />
                </div>

                <div className="marcasbg" style={{ display: `flex`, 'flexWrap': `wrap` }}>
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }}>
                        {/* <Fade > */}
                            {slide2}
                        {/* </Fade> */}
                    </div>
                    <div className="boxRightHeader  half noResponsive" style={{ width: `50%` }} >
                        {/* <Fade > */}
                            {slide2b}
                        {/* </Fade> */}
                    </div>
                    <div className="boxRightHeader  half responsive" style={{ width: `100%` }} >
                        <Fade >
                            {slide2c}
                        </Fade>
                    </div>
                    {contenedorMarcas}

                </div>


            </div> 
            <div className="bannerMedidas noResponsive"><img src={medidas} /></div>
            <div className="bannerMedidas responsive"><img src={medidasMovil} /></div>
             

        </div>


        <div className="boxesIni posRelative centrado">
        <div className="centrado">
                    <img src={imagen} width="1400" height="1000" className="slideMain" alt="Punto de Venta" />
                </div>
            <div>
                <FormContact url={bdc} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} />
            </div>
        </div>
        <BannerContacto url={bdc} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} />
        <Footer />

        {modalFormIsOpen &&
            <FormContactFicha url={bdcFicha} camion={nombreCamion} serie={nombreSerie} camionSerie={camionSerie} close={closeFormContact} />
        }
    </>
}

export default Camion