let precios = {
    iva: 'IVA Incluido',

    chasis_nqs: '$163.357.000',

    chasis_frs: '$171.957.000',

    tx_17000: '$292.357.000',

    tx_minimmula: '$339.657.000',

    tx_superMinimula: '$399.857.000',

    g7_540: '$537.457.000'
}


export default precios