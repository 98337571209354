import $ from "jquery";
import { useState, useEffect } from 'react';
import "../form.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import env from '../config';

let urlMedia = env.url
function FormContact(props) {

  let redireccion = "https://vehicentro.com/co/gracias-por-contactarnos"
  let url = props.url
  let nomCamion = props.camion
  let listSerie = (props.serie == "") ? '' : props.serie
  let listCamion = (props.camionSerie == "") ? '' : props.camionSerie


  let [nombres, setName] = useState('');
  let [ciudad, setCity] = useState('');
  let [email, setEmail] = useState('');
  let [celular, setTel] = useState('');
  let [identificacion, setCed] = useState('');
  let [serie, setSerie] = useState(listSerie);
  let [modelo_sinotruk, setModelo_sinotruk] = useState(listCamion);
  let [camion, setCam] = useState(nomCamion);
  let [escoge_tu_concesionario_mas_cercano, setCon] = useState('Concesionario');

  const [isChecked, setIsChecked] = useState(true);


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChange = (e) => {
    setName((e.target.value));
  }

  const handleChangeCity = (e) => {
    setCity(e.target.value);
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleChangeTel = (e) => {
    setTel(e.target.value);
  }

  const handleChangeCed = (e) => {
    setCed(e.target.value);
  }

  const handleChangeCon = (e) => {
    setCon(e.target.value);
  }

  //let camionesListOpt;
  const handleChangeSerie = (e) => {
    setSerie(e.target.value);
  }

  const handleChangeSerieCamion = (e) => {
    setModelo_sinotruk(e.target.value)
  }

  const handleChangeCam = (e) => {
    setCam(e.target.value);
  }



  const handleFocus = (e) => {
    let aux = e.target.closest('.input-group');
    if (validateName(e.target.value)) {
      aux.classList.add("errorClass")
      NotificationManager.error('Completar los datos requeridos.', '');
    } else {
      aux.classList.remove("errorClass")
    }
  }

  const validateName = (e) => {
    let auxNom = e.trim()
    if (auxNom == "" || auxNom.length < 5)
      return true
    else
      return false
  }


  const handleFocusEmail = (e) => {
    let aux = e.target.closest('.input-group');
    if (validateEmail(e.target.value)) {
      aux.classList.add("errorClass")
      NotificationManager.error('Email incorrecto.', '');
    } else {
      aux.classList.remove("errorClass")
    }
  }

  const validateEmail = (e) => {
    if (e == "" || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e)))
      return true
    else
      return false
  }

  const handleFocusTel = (e) => {
    let aux = e.target.closest('.input-group');
    if (validateTel(e.target.value)) {
      aux.classList.add("errorClass")
      NotificationManager.error('Numero telefonico incorrecto.', '');
    } else {
      aux.classList.remove("errorClass")
    }
  }

/*   const validateTel = (e) => {
    if (e === "" || !(/^\d+$/.test(e)) || e.length < 10 || e.length > 10)
      return true
    else
      return false
  } */

  const validateTel = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return !phoneRegex.test(phoneNumber);
  }

  const handleFocusCed = (e) => {
    let aux = e.target.closest('.input-group');
    if (validateCed(e.target.value)) {
      aux.classList.add("errorClass")
      NotificationManager.error('Cedula incorrecta.', '');
    } else {
      aux.classList.remove("errorClass")
    }
  }


  const validateCed = (e) => {
    if (e === "" || !(/^\d+$/.test(e)) || (e.length !== 10 && e.length !== 13))
      return true
    else
      return false
  }

  const handleFocusCon = (e) => {
    let aux = e.target.closest('.input-group');
    if (validateCon(e.target.value)) {
      aux.classList.add("errorClass")
      NotificationManager.error('Seleccionar concesionario.', '');
    } else {
      aux.classList.remove("errorClass")
    }
  }

  const validateCon = (e) => {
    if (e === "" || e === "Concesionario")
      return true
    else
      return false
  }

  function handleSumbit(e) {
    e.preventDefault();

    if (!validateName(e.target[0].value) && !validateName(e.target[1].value) && !validateEmail(e.target[2].value) && !validateTel(e.target[3].value) && !validateCed(e.target[4].value) && !validateCon(e.target[5].value)) {
      const form = $(e.target);
      $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize(),
        success(data) {
          NotificationManager.success('Datos enviados.', '');
          setName('')
          setCity('')
          setEmail('')
          setTel('')
          setCed('')
          window.location.href = redireccion;
        },
        error(data) {
          NotificationManager.success('Error form', data);
        }
      })
    } else {
      let form = $(e.target);
      console.log(form.serializeArray())
      NotificationManager.error('No se puede enviar datos, completar los datos correctamente.', '');
    }
// console.log("nombres",nombres)
// console.log("ciudad",ciudad)
// console.log("email",email)
// console.log("celular",celular)
// console.log("identificacion",identificacion)
// console.log("escoge_tu_concesionario_mas_cercano",escoge_tu_concesionario_mas_cercano)
// console.log("serie",serie)
// console.log("modelo_sinotruk",modelo_sinotruk)

  }
  useEffect(() => {
    setValues();
  }, [nomCamion])


  function setValues() {
    setSerie(listSerie)
    setModelo_sinotruk(listCamion)
    setCam(nomCamion)
  }

  const serie100 = ['NQS 9.9 Ton', 'FRS 10.4 Ton Super Turbo'];
 
  const serieTx = ['TX 250 4x2 PBV 17.000 Chasís','TX 350 4x2 Minimula', 'TX 400 4x2 Súper Minimula'];
 
  const serieG7 = ['G7 540 Tracto AMT'];

  const volquetas = ['Volquetas']

  const mixer = ['Mixer']

  let type = null;
  let options = null;

  if (serie === "Serie 100") {
    type = serie100;
  } else if (serie === "Serie Tx") {
    type = serieTx;
  } else if (serie === "Serie G7") {
    type = serieG7;
  } else if (serie === "Volquetas") {
    type = volquetas;
  } else if (serie === "Mixer") {
    type = mixer;
  } 

  
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }



  return <div>
    <div className="form-box">
      <h5 className="form-step"> ¿Necesitas información? </h5>
      <form action={url} method="post" onSubmit={(ev) => handleSumbit(ev)}>
        <div className="field1">
          <label> Cotizador Sinotruk Modelo {listCamion} </label>

          <label className="input_title">*Nombre y Apellido</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "user-solid.png"} /></span>
            <input placeholder="" name="nombres" type="text" onBlur={(e) => { handleFocus(e) }} onChange={(e) => { handleChange(e) }} value={nombres} />
          </div>

          <label className="input_title">*Ciudad</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "address-card-solid.png"} /></span>
            <input placeholder="" name="ciudad" type="text" onBlur={(e) => { handleFocus(e) }} onChange={(e) => { handleChangeCity(e) }} value={ciudad} />
          </div>

          <label className="input_title">*Email</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "envelope-solid.png"} /></span>
            <input placeholder="" name="email" type="text" onBlur={(e) => { handleFocusEmail(e) }} onChange={(e) => { handleChangeEmail(e) }} value={email} />
          </div>

          <label className="input_title">*Celular</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "phone-solid.png"} /></span>
            <input placeholder="" name="celular" type="text" onBlur={(e) => { handleFocusTel(e) }} onChange={(e) => { handleChangeTel(e) }} value={celular} />
          </div>

          <label className="input_title">*Cédula</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "portrait-solid.png"} /></span>
            <input placeholder="" name="identificacion" type="text" onBlur={(e) => { handleFocusCed(e) }} onChange={(e) => { handleChangeCed(e) }} value={identificacion} />
          </div>

          <label className="input_title">*Escoge tu concesionario más cercano</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "map-marker-solid.png"} /></span>
            <select name="escoge_tu_concesionario_mas_cercano" onBlur={(e) => { handleFocusCon(e) }} onChange={(e) => { handleChangeCon(e) }} value={escoge_tu_concesionario_mas_cercano}>
              <option value="Concesionario">Concesionario</option>
              <option value="Bogotá - Fontibón">Bogotá - Fontibón</option>
              <option value="Bogotá - Calle 80">Bogotá - Calle 80</option>
            </select>
          </div>

          <label className="input_title">*Serie</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "map-marker-solid.png"} /></span>
            <select name="serie" onBlur={(e) => { handleFocus(e) }} onChange={(e) => { handleChangeSerie(e) }} value={serie}>
              <option value="Serie 100">Serie 100</option>
              <option value="Serie Tx">Serie Tx</option>
              <option value="Serie G7">Serie G7</option>
              <option value="Volquetas">Volquetas</option>
              <option value="Mixer">Mixer</option>
            </select>
          </div>

          <label className="input_title">*Camión</label>
          <div className="input-group">
            <span className="userIcon"><img src={urlMedia + "map-marker-solid.png"} /></span>
            <select name="modelo_sinotruk" onChange={(e) => { handleChangeSerieCamion(e) }} value={modelo_sinotruk} >
              {options}
            </select>
          </div>


          <div className="input-group inputCamionInt">
            <span className="userIcon"></span>
            <input name="camion" type="text" value={camion} onChange={(e) => { handleChangeCam(e) }} />
          </div>

          <input name="redirect_url" value="https://vehicentro.com/gracias-por-contactarnos-sobre-camiones/" type="hidden" />
        </div>
        <div className="contenedorTerminos">
          <input
            className="inputTerminos"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label>
          <p className="pataLegal">
        Dando clic aquí usted autoriza de manera libre, voluntaria, previa, expresa e informada a Vehicentro Colombia S.A.S. a utilizar los datos personales y/o biométricos  consistentes en nombre, cédula, teléfono, correo electrónico e imágenes y videos capturados en formato físico o digital, para que los mismos sean usados conforme a la política de protección de privacidad que encuentra en:  www.vehicentro.com y para fines publicitarios, invitación a eventos, comerciales o de naturaleza análoga en los siguientes soportes: Página web, en todo tipo de redes sociales y medios de Internet, entre otros, sin carácter limitativo, catálogos, prensa, folletos, mailings, carteles, prensa, televisión, radio y otros medios de difusión. Los cuales podrán ser transferidos a terceros y/o aliados comerciales de Vehicentro Colombia S.A.S. para los fines antes previstos. 
        </p><br/>
        <p className="pataLegal">Los derechos como titular de datos son los previstos en la Constitución y en la norma colombiana aplicable al tratamiento de datos, especialmente los siguientes: a) Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento. b) Solicitar la actualización y rectificación de su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. c) Solicitar prueba de la autorización otorgada. d) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. e) Revocar la autorización y/o solicitar la supresión del dato, a menos que exista un deber legal o contractual que haga imperativo conservar la información. </p><br/>
        <p className="pataLegal"> La licencia de uso de la imagen se otorga a nivel mundial, esto quiere decir, que no existen restricciones territoriales para el uso y explotación de la misma, incluida la cesión de los derechos aquí conferidos.</p><br/>
          </label>
        </div>
        <button className={isChecked ? 'nextBtn' : 'nextBtnDisabled'} type="submit" disabled={!isChecked}>
          Cotizar
        </button>
        
        <NotificationContainer />
      </form>
    </div>

  </div>
}

export default FormContact