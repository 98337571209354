import { Helmet } from "react-helmet"
import BannerContacto from "../components/BannerContacto"
import Footer from "../components/Footer"
import NavBar from "../NavBar"
import NavBarTop from "../NavBarTop"
import env from '../config';
import React, { useState } from 'react';

let urlMedia = env.url

let media = urlMedia + "ensambladoraCol.mp4";

function Ensambladora() {
    return (
        <>
            <Helmet>
                <title>Ensambladora Sinotruk | Vehicentro</title>
                <meta name="description" content="Experiencia sinotruk" />
            </Helmet>
            <NavBarTop />
            <div className="containerPc3">
                <h1 className="tituloTestimionios">Ensambladora de Vehicentro Sinotruk Colombia</h1>
                    <div className="contenedorVideo2">
                        <video loop autoPlay muted controls playsInline preload="metadata" src={media + "#t=0.1"}></video>
                    </div>
            </div>  
            <BannerContacto />
            <Footer />
        </>
    )
}



export default Ensambladora