import { NavLink } from "react-router-dom"
import env from '../config';

let urlMedia = env.url

export default function Footer() {
    
    return (
        <div className="footer posRelative">
            <div className="info">
                <img src={urlMedia+"/home/vehicentroNewColombia2.png"} width="230" height="80" alt="Vehicentro" />
                <ul className="list">
                    <li><p className="titleFooter"><NavLink to="/compania">NOSOTROS</NavLink></p></li>
                    <li><p className="titleFooter"><NavLink to="/concesionarios">CONCESIONARIOS</NavLink></p></li>
                    {/* <li><p>VEINSTEIN</p></li>
                    <li><p>TECNOLOGÍA ALEMANA</p></li> */}
                </ul>
                <br />
                <p className="title titleNumero"><a href="tel:573106392803">+57 310 6392803</a></p>
                <p className="title titleContacto">CONTACTO DIRECTO</p>
                <div className="social row">
                    <div className="flex-row imgMain">
                        <a href="https://www.facebook.com/Vehicentro"><img src={urlMedia+"facebook.png"} alt="facebook" width="32" height="32" /></a>
                        <a href="https://www.instagram.com/vehicentroec"><img src={urlMedia+"instagram.png"} alt="instagram" width="32" height="32" /></a>
                        <a href="https://www.youtube.com/channel/UCW8gGawovE35cXcJ3P2kryQ"><img src={urlMedia+"youtube.png"} alt="youtube" width="32" height="32" /></a>
                        <a href="https://www.tiktok.com/@sinotrukeros"><img src={urlMedia+"tiktok.webp"} alt="tiktok" width="32" height="32" /></a>
                        <a href="https://www.linkedin.com/company/vehicentroecu/?originalSubdomain=ec"><img src={urlMedia+"linkdin.png"} alt="linkdin" width="32" height="32" /></a>
                    </div>

                </div>
                {/*<p className="title titleContacto">Realizado por Agencia Prez</p>*/}
                
            </div>
            
            <div className="col1 colSell">
                <p className="title">PRODUCTOS</p>
                <ul className="list listNonTitle">
                    <li><p className="itemMenu">SERIE 100</p></li>
                    <li><p className="itemMenu less"><NavLink to="/camiones/NQS-9.9-Ton">- NQS 9.9 Ton</NavLink></p></li>
                    <li><p className="itemMenu less"><NavLink to="/camiones/FRS-10.4-Ton-Super-Turbo">- FRS 10.4 Ton Súper Turbo</NavLink></p></li>
                    <br></br>
                    <li><p className="itemMenu">SERIE TX</p></li>
                    <li><p className="itemMenu less"><NavLink to="/camiones/TX-250-4x2-PBV-17.000-Chasis">- TX 250 4x2 PBV 17.000 Chasís</NavLink></p></li>
                    <li><p className="itemMenu less"><NavLink to="/cabezales/TX-350-4x2-Minimula">- TX 350 4x2 Minimula</NavLink></p></li>
                    <li><p className="itemMenu less"><NavLink to="/cabezales/TX-400-4x2-Super-Minimula"> - TX 400 4x2 Súper Minimula</NavLink></p></li>
                    <br></br>
                    <li><p className="itemMenu">SERIE G7</p></li>
                    <li><p className="itemMenu less"><NavLink to="/cabezales/G7-540-Tracto-AMT">- G7 540 Tracto AMT</NavLink></p></li>
                </ul>
                {/* <ul className="list">
                    <li><p className="subTitle">SUNWARD</p></li>
                    <li><p className="titleFooter"><a href="/excavadoras/swe210">Excavadora SWE210</a></p></li>
                    <li><p className="titleFooter"><a href="/excavadoras/swe365e">Excavadora SWE365E</a></p></li>
                </ul> */}

            </div>
            
            <div className="col1 colSell">
                <p className="title">POSVENTA</p>
                <ul className="list listNonTitle">
                    <li><p className="itemMenu"><NavLink to="/concesionarios">Concesionarios</NavLink></p></li>
                    {/* <li><p className="itemMenu"><a href="/repuestos">Repuestos</a></p></li>
                    <li><p className="itemMenu">Mantenimiento</p></li> */}
                    <li><p className="itemMenu"><NavLink to="/garantia">Garantía</NavLink></p></li>
                </ul>
            </div>
            {/* <div className="col1 colTech">
                <p className="title">TECNOLOGÍA</p>
                <ul className="list listNonTitle">
                    <li><p className="itemMenu"><a href="/motor-mc">Componentes</a></p></li>
                    <li><p className="itemMenu"><a href="/rendimiento/100">Medición de rendimiento</a></p></li>
                </ul>

                
            </div> */}
            <a href="https://wa.me/573147082286?text=Hola,%20Vehicentro%20Colombia" className="btn-wsp" target="_blank">
                    <img className="imgWpp" width="60" height="60" src={urlMedia+"wpp.gif"} alt="Whatsapp"></img>
                </a>
        </div>
    )
}
