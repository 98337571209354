import BannerContacto from "../components/BannerContacto"
import Footer from "../components/Footer"
import NavBarTop from "../NavBarTop"

function Motor_mc() {
    return (
        <>
            <NavBarTop />
            <Content1 />
            <BannerContacto />
            <Footer />
        </>
    )
}

function Content1() {
    return (
        <div className="privaty">
        <div className="texto">
            <h1>Política de protección de privacidad</h1>
            <br/><br/>
         <p className="pataLegal">
        Usted autoriza de manera libre, voluntaria, previa, expresa e informada a Vehicentro Colombia S.A.S. a utilizar los datos personales y/o biométricos  consistentes en nombre, cédula, teléfono, correo electrónico e imágenes y videos capturados en formato físico o digital, para que los mismos sean usados conforme a la política de protección de privacidad que encuentra en:  www.vehicentro.com y para fines publicitarios, invitación a eventos, comerciales o de naturaleza análoga en los siguientes soportes: Página web, en todo tipo de redes sociales y medios de Internet, entre otros, sin carácter limitativo, catálogos, prensa, folletos, mailings, carteles, prensa, televisión, radio y otros medios de difusión. Los cuales podrán ser transferidos a terceros y/o aliados comerciales de Vehicentro Colombia S.A.S. para los fines antes previstos. 
        </p><br/>
        <p className="pataLegal">Los derechos como titular de datos son los previstos en la Constitución y en la norma colombiana aplicable al tratamiento de datos, especialmente los siguientes: a) Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento. b) Solicitar la actualización y rectificación de su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. c) Solicitar prueba de la autorización otorgada. d) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. e) Revocar la autorización y/o solicitar la supresión del dato, a menos que exista un deber legal o contractual que haga imperativo conservar la información. </p><br/>
        <p className="pataLegal"> La licencia de uso de la imagen se otorga a nivel mundial, esto quiere decir, que no existen restricciones territoriales para el uso y explotación de la misma, incluida la cesión de los derechos aquí conferidos.</p><br/>
        </div>
    </div>
    )
}

export default Motor_mc